export const documentTypes = ["Contracts", "Policies", "Florida_blue"];

export const contractedRateFilterOptions = [
  { field: "Procedure", value: "procedure" },
  { field: "Payer", value: "payer" },
  { field: "Plan", value: "plan" },
  { field: "Locality", value: "locality" },
];

export const navigationItems = [
  { name: "Overview", id: 1, href: "overview" },
  {
    name: "Recon Copilot",
    id: 2,
    href: "chat",
  },
  {
    name: "Contracts",
    id: 3,
    href: "billing",
  },
  {
    name: "Contracted Rates",
    id: 4,
    href: "contracted-rates",
  },
  {
    name: "Filing Requirements",
    id: 5,
    href: "filing",
  },
  {
    name: "Prior Authorizations",
    id: 6,
    href: "prior-auth",
  },

  {
    name: "Denials",
    id: 7,
    href: "denials",
  },

  {
    name: "Payment Variances",
    id: 8,
    href: "payment-variances",
  },

  {
    name: "Patient Payments",
    id: 9,
    href: "patient-payments",
  },
  {
    name: "Phone Agent",
    id: 10,
    href: "ai-phone-agent",
  },

  {
    name: "Test Tool",
    id: 11,
    href: "insight-engine",
  },

  {
    name: "Use Case",
    id: 12,
    href: "use-case",
  },

  {
    name: "Audit Result",
    id: 13,
    href: "audit-result",
  },

  {
    name: "Review Audit",
    id: 14,
    href: "review-audit",
  },
  {
    name: "Question Studio",
    id: 15,
    href: "question-studio",
  },

  {
    name: "Home",
    id: 16,
    href: "denials-home",
  },
];

export const columnDisplayNames = {
  provider_type: "Provider",
  payer_name: "Payer",
  cpt_code: "CPT",
  carrier_code: "Carrier",
  locality_code: "Locality",
  modifier: "Modifier",
  effective_date_start: "Effective Date",
  effective_date_end: "Termination Date",
  plan_name: "Plan",
  fee_schedule_name: "Fee Schedule",
  adjusted_fee: "Contracted Rate",
  reimbursement_amount: "Schedule Rate",
  percentage: "Schedule Percentage",
  clause_id: "Clause",
};

export const excludedFields = [
  "cfs_id",
  "provider_type_id",
  "fee_schedule_id",
  "fsa_id",
  "created_at",
  "updated_at",
  "clause_description",
  "payer_description",
  "page_number",
  "bbox_coordinates",
  "locality_description",
  "s3_file_path",
  "plan_type",
  "plan_id",
  "group_id",
];
