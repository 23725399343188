import React, { useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  MessageCircle,
  ChevronRight,
  ThumbsUp,
  ThumbsDown,
  MessageSquare,
} from "lucide-react";

const ReviewAuditsPage = () => {
  const { providerName } = useParams();
  const navigate = useNavigate();

  const [rowData] = useState([
    {
      provider: "Michael Williams",
      uploadDate: "Aug 29, 2023 at 05:43 am",
      score: 69,
      auditStatus: "Completed",
      reviewStatus: "Needs Review",
    },
    {
      provider: "Sarah Brown",
      uploadDate: "Aug 29, 2023 at 05:43 am",
      score: 75,
      auditStatus: "Completed",
      reviewStatus: "Needs Review",
    },
    {
      provider: "Lisa Chen",
      uploadDate: "Sep 01, 2023 at 02:30 pm",
      score: 72,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
    {
      provider: "William Anderson",
      uploadDate: "Sep 03, 2023 at 11:20 am",
      score: 67,
      auditStatus: "Not Started",
      reviewStatus: "Needs Review",
    },
    {
      provider: "Daniel Thompson",
      uploadDate: "Sep 04, 2023 at 08:30 am",
      score: 79,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
  ]);

  const [columnDefs] = useState([
    { field: "provider", headerName: "Provider", minWidth: 150 },
    {
      field: "uploadDate",
      headerName: "Upload Date",
      sort: "desc",
      minWidth: 180,
    },
    {
      field: "score",
      headerName: "Score",
      minWidth: 100,
      valueFormatter: (params) => `${params.value}%`,
    },
    { field: "auditStatus", headerName: "Audit Status", minWidth: 130 },
    { field: "reviewStatus", headerName: "Review Status", minWidth: 130 },
    {
      headerName: "Actions",
      minWidth: 100,
      cellRenderer: (params) => (
        <button
          className="bg-purple-600 text-white px-3 py-1 rounded text-sm"
          onClick={() => navigate(`/review-audit/${params.data.provider}`)}
        >
          Review
        </button>
      ),
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const auditQuestions = [
    {
      question: "Is the type of session present in the report?",
      answer: "No",
      context: "The type of session is not present in the report.",
    },
    {
      question: "Were anti psychotics prescribed?",
      answer: "No",
      context:
        "The document states that the patient is currently only taking Melatonin 3mg QHS and denies any side effects from the medication.",
    },
    {
      question:
        "Are allergies or 'no known drug allergies' present in the documentation?",
      answer: "No",
      context: "Allergies are not listed in the report.",
    },
    {
      question:
        "Are goals present in the treatment plan measurable and specific?",
      answer: "No",
      context:
        "It is mentioned that the Psychiatric team will monitor mood and behavior, but the goals are not measurable and specific.",
    },
    {
      question: "Is face-to-face time congruent with the CPT code billed?",
      answer: "No",
      context:
        "Face to face time was documented as 54 minutes when a 90834 code was billed. This could be billed as a 90837.",
    },
    {
      question:
        "Is there a documented plan for what is going to happen next or further sessions?",
      answer: "No",
      context:
        "It is mentioned that there will be a follow up in 30 days but there is not a sufficiently documented plan for further sessions.",
    },
    {
      question:
        "Give a brief summary of the medical report with any information that is helpful to audit the documentation.",
      answer: "N/A",
      context:
        "A 70-year-old female patient, previously diagnosed with Schizophrenia, underwent a comprehensive psychiatric evaluation due to mood symptoms. Despite her diagnosis, she currently exhibits no schizophrenic symptoms, denies any feelings of depression, anxiety, hallucinations, or paranoia, and is benefiting from her current medication, melatonin, for sleep initiation.",
    },
  ];

  const renderAuditsList = () => (
    <div className="p-4 h-screen">
      <h1 className="text-2xl font-bold mb-4">Audits Pending Review</h1>
      <div
        className="ag-theme-quartz w-full h-full"
        style={{
          "--ag-row-border-width": "1px",
          "--ag-row-border-color": "#e2e8f0",
          "--ag-cell-horizontal-border": "solid 1px #e2e8f0",
          "--ag-font-size": "14px",
          "--ag-header-font-size": "18px",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout="normal"
          suppressColumnVirtualisation={true}
        />
      </div>
    </div>
  );

  const renderAuditDetail = () => (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="py-4 px-4">
        <h1 className="text-2xl font-bold mb-4">Audit Review</h1>
      </div>

      <div className="flex flex-col xl:flex-row flex-grow overflow-hidden">
        {/* PDF Viewer Placeholder */}
        <div className="w-full xl:w-1/2 bg-gray-300 p-4 flex-shrink-0">
          <div className="h-full flex items-center justify-center">
            <iframe
              src="https://drive.google.com/file/d/1IpacQ4z-tl_b8UIV3vD9bI03KW1hX_-G/preview"
              className="w-full h-[100%]"
              allow="autoplay"
            ></iframe>
          </div>
        </div>

        {/* Audit Review Section */}
        <div className="w-full xl:w-1/2 px-4 flex flex-col">
          <div className="bg-white rounded-lg shadow p-4 md:p-6 flex flex-col flex-grow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg md:text-xl font-semibold">
                Provider: {providerName}
              </h2>
              <div className="flex items-center space-x-2">
                <span className="text-purple-600 cursor-pointer">
                  <MessageCircle size={20} />
                </span>
                <button className="bg-red-100 text-red-600 px-3 py-1 rounded-full text-sm font-medium">
                  Needs Review
                </button>
              </div>
            </div>
            <div className="overflow-x-auto flex-grow flex flex-col">
              <table className="w-full min-w-[640px]">
                <thead className="bg-white sticky top-0">
                  <tr className="text-gray-600 text-sm">
                    <th className="pb-2 pr-4 text-left text-sm">QUESTION</th>
                    <th className="pb-2 pr-4 text-center text-sm">ANSWER</th>
                    <th className="pb-2 pr-4 text-center text-sm">CONTEXT</th>
                    <th className="pb-2 text-center text-sm">
                      RESPONSE QUALITY
                    </th>
                  </tr>
                </thead>
                <tbody className="overflow-y-scroll flex-grow">
                  {auditQuestions.map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-pink-50 px-2" : "px-2"}
                    >
                      <td className="py-2 pr-4 text-[14px]">{item.question}</td>
                      <td className="py-2 pr-4 text-center text-[14px]">
                        {item.answer}
                      </td>
                      <td className="py-2 pr-4 text-[14px]">{item.context}</td>
                      <td className="py-2">
                        <div className="flex justify-center space-x-2">
                          <button className="text-gray-400 hover:text-green-500">
                            <ThumbsUp size={16} />
                          </button>
                          <button className="text-gray-400 hover:text-red-500">
                            <ThumbsDown size={16} />
                          </button>
                          <button className="text-gray-400 hover:text-blue-500">
                            <MessageSquare size={16} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0">
              <button className="bg-purple-600 text-white px-4 py-2 rounded w-full sm:w-auto">
                Mark Reviewed
              </button>
              <button className="bg-purple-600 text-white px-4 py-2 rounded flex items-center justify-center w-full sm:w-auto">
                Mark Reviewed and Next
                <ChevronRight size={20} className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return providerName ? renderAuditDetail() : renderAuditsList();
};

export default ReviewAuditsPage;
