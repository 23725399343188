"use client";

import React, { useContext, useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  PlusIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { excludedFields as defaultExcludedFields } from "../../lib/constants";
import { ModalContext } from "../../context/modal-context";
import { columnDisplayNames } from "../../lib/constants";

export const ColumnVisibilityModal = ({ isOpen, onClose, activeColumns }) => {
  const { excludedFields, setExcludedFields } = useContext(ModalContext);
  const [localVisibility, setLocalVisibility] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const initialVisibility = {};
    // Only initialize visibility for columns that aren't in defaultExcludedFields
    activeColumns.forEach((column) => {
      if (!defaultExcludedFields.includes(column.key)) {
        initialVisibility[column.key] = !excludedFields.includes(column.key);
      }
    });
    // Only include non-default excluded fields
    excludedFields.forEach((field) => {
      if (
        !defaultExcludedFields.includes(field) &&
        !activeColumns.some((col) => col.key === field)
      ) {
        initialVisibility[field] = false;
      }
    });
    setLocalVisibility(initialVisibility);
  }, [activeColumns, excludedFields]);

  const removeColumn = (columnKey) => {
    setLocalVisibility((prev) => ({
      ...prev,
      [columnKey]: true,
    }));
    setSearchQuery("");
  };

  const addColumn = (columnKey) => {
    if (columnKey) {
      setLocalVisibility((prev) => ({
        ...prev,
        [columnKey]: false,
      }));
      setSearchQuery("");
    }
  };

  const handleSave = () => {
    // Preserve default excluded fields when saving
    const newExcludedFields = [
      ...defaultExcludedFields,
      ...Object.entries(localVisibility)
        .filter(([_, isVisible]) => !isVisible)
        .map(([key]) => key),
    ];

    setExcludedFields(newExcludedFields);
    localStorage.setItem(
      "insight_excluded_fields",
      JSON.stringify(newExcludedFields)
    );
    onClose(false);
  };

  const handleReset = () => {
    setExcludedFields(defaultExcludedFields);
    localStorage.setItem(
      "insight_excluded_fields",
      JSON.stringify(defaultExcludedFields)
    );
  };

  const getDisplayLabel = (key) => {
    if (columnDisplayNames[key]) {
      return columnDisplayNames[key];
    }
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Filter out default excluded fields from localVisibility
  const filteredLocalVisibility = Object.entries(localVisibility)
    .filter(([key]) => !defaultExcludedFields.includes(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const allColumns = Object.entries(filteredLocalVisibility).map(
    ([key, isVisible]) => ({
      key,
      label: getDisplayLabel(key),
      isVisible,
    })
  );

  const filteredColumns = allColumns.filter((column) =>
    column.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const hiddenColumns = allColumns.filter((col) => !col.isVisible);
  const availableColumns = filteredColumns.filter((col) => col.isVisible);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                  onClick={() => onClose(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <Dialog.Title className="text-lg font-semibold leading-7 text-gray-900">
                  Manage Column Visibility
                </Dialog.Title>

                <div className="mt-4">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search columns..."
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Hidden Columns{" "}
                    {hiddenColumns.length > 0 && `(${hiddenColumns.length})`}
                  </label>
                  <div className="flex flex-wrap gap-2 min-h-12 max-h-[8rem] p-2 border rounded-md bg-gray-50 overflow-y-auto">
                    {hiddenColumns.length > 0 ? (
                      hiddenColumns.map((column) => (
                        <span
                          key={column.key}
                          className="inline-flex items-center px-2 py-1 rounded-md text-sm bg-gray-200 text-gray-700"
                        >
                          {column.label}
                          <button
                            onClick={() => removeColumn(column.key)}
                            className="ml-1 p-1 hover:bg-gray-300 rounded"
                          >
                            <XMarkIcon className="h-4 w-4" />
                          </button>
                        </span>
                      ))
                    ) : (
                      <span className="text-gray-500 text-sm">
                        {searchQuery
                          ? "No matching hidden columns"
                          : "No hidden columns"}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Displayed Columns{" "}
                    {availableColumns.length > 0 &&
                      `(${availableColumns.length})`}
                  </label>
                  <div className="max-h-48 overflow-y-auto">
                    {availableColumns.length > 0 ? (
                      availableColumns.map((column) => (
                        <div
                          key={column.key}
                          className="flex items-center justify-between px-3 py-2 hover:bg-gray-50"
                        >
                          <span className="text-sm text-gray-900">
                            {column.label}
                          </span>
                          <button
                            onClick={() => addColumn(column.key)}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Hide Column
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-500 text-sm p-2">
                        {searchQuery
                          ? "No matching available columns"
                          : "No available columns"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 flex justify-between items-center">
                  <button
                    onClick={() => handleReset()}
                    className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 "
                  >
                    Reset to default
                  </button>
                  <div className="flex space-x-2">
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ColumnVisibilityModal;
