import React, { useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Share2, Copy, Check, Lock, Trash2, Link } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ModalContext } from "../../context/modal-context";
import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useUserContext } from "../../context/user-context";

export const ShareInsightModal = ({ isOpen, setIsOpen }) => {
  const { userInformation } = useUserContext();
  const [activeTab, setActiveTab] = useState("create"); // 'create' or 'manage'
  const location = useLocation();
  const { filters } = useContext(ModalContext);

  // States for Create Share Link tab
  const [password, setPassword] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [error, setError] = useState("");

  // States for Manage Links tab
  const [sharedLinks, setSharedLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [manageError, setManageError] = useState("");

  const tabs = [
    { id: "create", name: "Create Share Link" },
    { id: "manage", name: "Manage Shared Links" },
  ];

  useEffect(() => {
    if (isOpen && activeTab === "manage") {
      fetchSharedLinks();
    }
  }, [isOpen, activeTab, userInformation]);

  const fetchSharedLinks = async () => {
    setIsLoading(true);
    setManageError("");
    try {
      const q = query(
        collection(db, "share_links"),
        where("user_id", "==", userInformation?.id),
        where("organizationId", "==", userInformation?.organizationId)
      );
      const querySnapshot = await getDocs(q);
      const links = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSharedLinks(links);
    } catch (error) {
      console.error("Error fetching shared links: ", error);
      setManageError("Failed to load shared links. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setPassword("");
    setShareLink("");
    setIsShared(false);
    setIsCopied(false);
    setIsSharing(false);
    setError("");
    setActiveTab("create");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleShare = async () => {
    if (password.trim() === "") {
      setError("Password is required");
      return;
    }

    setIsSharing(true);
    const uuid = uuidv4();
    const newShareLink = `${window.location.origin}${location.pathname}/share/${uuid}`;
    setShareLink(newShareLink);

    const shareData = {
      organizationId: userInformation?.organizationId,
      user_id: userInformation?.id,
      filters,
      link: newShareLink,
      sharedLinkPassword: password,
    };

    try {
      await addDoc(collection(db, "share_links"), shareData);
      setIsSharing(false);
      setIsShared(true);
      fetchSharedLinks(); // Refresh the list of shared links
    } catch (error) {
      console.error("Error adding document: ", error);
      setIsSharing(false);
      setError("An error occurred while sharing. Please try again.");
    }
  };

  const handleDeleteLink = async (linkId) => {
    try {
      await deleteDoc(doc(db, "share_links", linkId));
      setSharedLinks(sharedLinks.filter((link) => link.id !== linkId));
    } catch (error) {
      console.error("Error deleting shared link: ", error);
      setManageError("Failed to delete the link. Please try again.");
    }
  };

  const handleCopy = (linkId = null) => {
    if (linkId) {
      setCopiedLinkId(linkId);
      setTimeout(() => setCopiedLinkId(null), 2000);
    } else {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          setIsOpen(false);
          resetState();
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center mb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    Share
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setIsOpen(false);
                      resetState();
                    }}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                {/* Tabs */}
                <div className="border-b border-gray-200 mb-6">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <button
                        key={tab.id}
                        onClick={() => setActiveTab(tab.id)}
                        className={`
                          whitespace-nowrap px-1 py-4 text-sm font-medium border-b-2
                          ${
                            activeTab === tab.id
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                          }
                        `}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>

                {/* Tab Content */}
                {activeTab === "create" ? (
                  // Create Share Link Tab
                  <div className="mt-2">
                    {!isShared ? (
                      <>
                        <div className="mb-4">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Set a password{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <Lock
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              onChange={handlePasswordChange}
                              className={`block w-full pl-10 pr-3 py-2 border ${
                                error ? "border-red-300" : "border-gray-300"
                              } rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                              placeholder="Enter password"
                              required
                            />
                          </div>
                          {error && (
                            <p className="mt-2 text-sm text-red-600">{error}</p>
                          )}
                        </div>
                        <button
                          onClick={handleShare}
                          disabled={isSharing}
                          className="w-full flex justify-center items-center px-4 py-2 bg-violet-600 hover:bg-violet-700 rounded-lg text-white text-sm font-medium transition-colors duration-200"
                        >
                          {isSharing ? (
                            <>
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                />
                              </svg>
                              Sharing...
                            </>
                          ) : (
                            <>
                              <Share2 className="h-5 w-5 mr-2" />
                              Generate Provider-View Link
                            </>
                          )}
                        </button>
                      </>
                    ) : (
                      <div className="space-y-4">
                        <div className="bg-gray-50 p-4 rounded-md">
                          <p className="text-sm font-medium text-gray-900 mb-1">
                            Share Link
                          </p>
                          <div className="flex items-center justify-between bg-white p-2 rounded border border-gray-300">
                            <span className="text-sm truncate mr-2 flex-grow">
                              {shareLink}
                            </span>
                            <CopyToClipboard
                              text={shareLink}
                              onCopy={handleCopy}
                            >
                              <button className="flex-shrink-0 ml-2 p-1 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                {isCopied ? (
                                  <Check className="h-5 w-5 text-green-500" />
                                ) : (
                                  <Copy className="h-5 w-5 text-gray-500" />
                                )}
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500">
                          This link can be used to share your insight with
                          others. They will need to enter the password you set
                          to access it.
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  // Manage Links Tab
                  <div className="mt-2">
                    {isLoading ? (
                      <div className="text-center py-4">
                        <svg
                          className="animate-spin h-5 w-5 mx-auto text-indigo-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        <p className="mt-2 text-sm text-gray-500">
                          Loading shared links...
                        </p>
                      </div>
                    ) : manageError ? (
                      <p className="text-sm text-red-600">{manageError}</p>
                    ) : sharedLinks.length === 0 ? (
                      <p className="text-sm text-gray-500 text-center py-4">
                        No shared links found.
                      </p>
                    ) : (
                      <div className="max-h-64 overflow-y-auto">
                        <ul className="space-y-4 px-1">
                          {sharedLinks.map((link) => (
                            <li
                              key={link.id}
                              className="bg-gray-50 p-4 rounded-md space-y-2"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex-grow">
                                  <div className="flex items-center space-x-4 mb-2">
                                    <CopyToClipboard
                                      text={link.link}
                                      onCopy={() => handleCopy(link.id)}
                                    >
                                      <button className=" rounded-md hover:bg-gray-200 focus:outline-none ">
                                        {copiedLinkId === link.id ? (
                                          <Check className="h-5 w-5 text-green-500" />
                                        ) : (
                                          <Copy className="h-5 w-5 text-gray-500" />
                                        )}
                                      </button>
                                    </CopyToClipboard>
                                    <span className="text-sm text-gray-900 truncate max-w-[200px]">
                                      {link.link}
                                    </span>
                                  </div>
                                  <div className="flex items-center space-x-4">
                                    <Lock className="h-5 w-5 text-gray-400" />
                                    <span className="text-sm text-gray-600">
                                      Password: {link.sharedLinkPassword}
                                    </span>
                                  </div>
                                </div>
                                <button
                                  onClick={() => handleDeleteLink(link.id)}
                                  className="p-1 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                  <Trash2 className="h-5 w-5 text-red-500" />
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareInsightModal;
