import CustomBlockTable from "../../components/CustomBlockTable";
import React, { useEffect, useState, useContext } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { ModalContext } from "../../context/modal-context";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { useUserContext } from "../../context/user-context";

import { contractedRateFilterOptions } from "../../lib/constants";
import { RECON_OS_SERVER_URI } from "@/lib/services";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ContractedRatesPage = () => {
  const { userInformation } = useUserContext();
  const auth = getAuth();
  const {
    setStartPage,
    setOpenPdfViewer,
    setContractModalPdfDetails,
    // openPdfViewer,
    // startPage,

    // type,
    // setType,
    // contractModalPdfDetails,
  } = useContext(ModalContext);

  const contractRateHeaders = [
    { field: "Procedure", value: "procedure" },
    { field: "Payer", value: "payer" },
    // { field: "Plan", value: "plan" },
    // { field: "Contract Title", value: "contractTitle" },
    { field: "Locality", value: "locality" },

    // { field: "Locality Details", value: "placeOfService" },
    // { field: "Provider Type", value: "providerType" },
    // { field: "Fee Sched.", value: "feeSchedule" },
    // { field: "Fee Struct.", value: "feeStructure" },
    { field: "Rate Eff.", value: "rateEffectiveStartDate" },
    { field: "Rate Eff. End", value: "rateEffectiveEndDate" },
    { field: "Rate", value: "rate" },
  ];

  const DEFAULT_DEMO_CONTRACT_FILE_PATH = "demo/ICO Blue Choice PPO.pdf";

  const [contractRates, setContractRates] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [fullContractRates, setFullContractRates] = useState([]);
  const [pageSnapshots, setPageSnapshots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("procedure");
  const [contractModalDetails, setContractModalDetails] = useState();

  useEffect(() => {
    async function fetchData() {
      if (userInformation?.organizationId) {
        const token = await auth.currentUser.getIdToken();
        if (userInformation?.organizationId === 1) {
          getContractRates(userInformation?.organizationId);
        } else {
          handleContracts(token, userInformation?.organizationId);
          handleContractedRates(token, userInformation?.organizationId);
        }
      }
    }
    fetchData();
  }, [userInformation]);

  useEffect(() => {
    if (contractRates.length > 0) {
      getContractModalsInfo();
    }
  }, [contractRates]);

  const getContractModalsInfo = async () => {
    try {
      // Extract docIds from contractRates
      const docIds = contractRates.map((rate) => rate.docId);

      // Firestore 'whereIn' query can only match up to 10 items
      if (docIds.length > 10) {
        console.error(
          "Too many docIds, Firestore 'whereIn' can only match up to 10 items"
        );
        // Handle this case appropriately, maybe by splitting the docIds array
        // and performing multiple queries or refactoring your data model.
        return;
      }

      // Create a query with 'whereIn' to match contractId with docIds
      let q = query(
        collection(db, "contract_rates_modal"),
        where("contractId", "in", docIds)
      );

      const snapshot = await getDocs(q);
      const contractModalData = snapshot.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));

      console.log("The Modal data", contractModalData);
      setContractModalDetails(contractModalData);
    } catch (error) {
      console.error("Error fetching contract modals:", error);
      // Handle the error appropriately
    }
  };
  const getContractRates = async (organizationId, pageDirection = "next") => {
    try {
      let q;
      if (pageDirection === "next" && lastVisible) {
        q = query(
          collection(db, "contracted_rates"),
          where("organizationId", "==", organizationId),
          startAfter(lastVisible),
          limit(itemsPerPage)
        );
      } else if (pageDirection === "prev" && currentPage > 1) {
        const prevPageSnapshot = pageSnapshots[pageSnapshots.length - 2];
        q = query(
          collection(db, "contracted_rates"),
          where("organizationId", "==", organizationId),
          startAfter(prevPageSnapshot),
          limit(itemsPerPage)
        );
      } else {
        q = query(
          collection(db, "contracted_rates"),
          where("organizationId", "==", organizationId),
          limit(itemsPerPage)
        );
      }

      const snapshot = await getDocs(q);
      const contractData = snapshot.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));

      if (contractData.length > 0) {
        if (pageDirection === "next") {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setPageSnapshots([...pageSnapshots, snapshot.docs[0]]);
        } else if (pageDirection === "prev") {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setPageSnapshots(pageSnapshots.slice(0, -1));
        }
      }

      setIsNextDisabled(contractData.length < itemsPerPage);

      const sortedContract = contractData.sort((a, b) => {
        if (a.procedure === "90500" || a.procedure === "99444") {
          return -1;
        }
        if (b.procedure === "90500" || b.procedure === "99444") {
          return 1;
        }
        return 0;
      });

      setContractRates(sortedContract);
    } catch (error) {
      console.error("Error fetching contract rates:", error);
      // Handle the error appropriately
      // For example, you might want to set an error state or show a notification
    }
  };
  const handleContracts = async (token, organizationId, pageSize = 10) => {
    try {
      const response = await axios.get(`${RECON_OS_SERVER_URI}/contracts`, {
        headers: {
          Authorization: token, // Replace with your Firebase token
        },
        params: {
          organization_id: organizationId, // Replace with the actual organization ID
          page: currentPage,
          pageSize: pageSize,
        },
      });

      setContracts(response.data);

      console.log("Data fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
    }
  };

  const handleContractedRates = async (
    token,
    organizationId,
    pageSize = 10
  ) => {
    try {
      setIsLoading(true);
      // TODO: update this table to hold the contracted rates from the real database or just don't use it in organizations outside of "demo"
      // const contractedRatesURL = organizationId == 1 ? `${RECON_OS_SERVER_URI}/contracted-rates-demo` : `${RECON_OS_SERVER_URI}/contracted-rates`

      const contractedRatesURL =
        organizationId == 1
          ? `${RECON_OS_SERVER_URI}/contracted-rates-demo`
          : `${RECON_OS_SERVER_URI}/contracted-rates`;
      const response = await axios.get(contractedRatesURL, {
        headers: {
          Authorization: token, // Replace with your Firebase token
        },
        params: {
          organization_id: organizationId, // Replace with the actual organization ID
          page: currentPage,
          pageSize: pageSize,
        },
      });

      setContractRates(response.data);
      setIsLoading(false);
      console.log("Data fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
    }
  };

  const handleNextPage = async () => {
    if (!isNextDisabled) {
      const token = await auth.currentUser.getIdToken();
      setCurrentPage(currentPage + 1);
      if (userInformation?.organizationId == 1) {
        getContractRates(userInformation?.organizationId, "next");
      } else {
        handleContractedRates(token, userInformation?.organizationId);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);

      const token = await auth.currentUser.getIdToken();
      if (userInformation?.organizationId == 1) {
        getContractRates(userInformation?.organizationId, "prev");
      } else {
        handleContractedRates(token, userInformation?.organizationId);
      }
    }
  };

  const searchPolicies = (term, field) => {
    if (term.trim() === "") {
      return contractRates; // Return all policies if search term is empty
    }

    const lowerCaseTerm = term.toLowerCase();

    return contractRates.filter((policy) => {
      const fieldValue = policy[field]?.toString().toLowerCase();
      return fieldValue && fieldValue.includes(lowerCaseTerm);
    });
  };

  const truncateText = (text, maxLength = 30) => {
    if (text?.length > maxLength) {
      return text.substr(0, maxLength - 3) + "...";
    }
    return text;
  };

  const filteredPolicies = searchPolicies(searchTerm, searchField);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/(\d+)(?:st|nd|rd|th)/, (_, day) => {
        const suffix =
          ["th", "st", "nd", "rd"][day > 3 && day < 21 ? 0 : day % 10] || "th";
        return `${day}${suffix}`;
      });
  };
  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8 overflow-hidden bg-gray-100">
      <div className="min-h-screen bg-gray-100 overflow-hidden">
        <div className="min-h-screen bg-gray-100">
          <div className=" ">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-2xl underline-offset-4 font-semibold py-2">
                  Contracted Rates
                </h1>
              </div>
            </div>
            {/* <div className="flex mt-4 flex-row justify-between items-end space-x-4 mb-4 ">
              <div className="flex flex-row  items-end space-x-4 ">
                <div className="relative flex  ">
                  <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div>
                  <select
                    id="location"
                    name="location"
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                    className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {contractedRateFilterOptions?.map((item, indexId) => {
                      return (
                        <option value={item.value} key={indexId}>
                          {item.field}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div> */}
            <div className="mt-8 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    {isLoading ? (
                      <div className="flex flex-col justify-center items-center p-8">
                        <div className="loader"></div>
                        <div className="mt-4 text-center">
                          <p className="text-sm font-medium text-gray-700">
                            Loading data...
                          </p>
                        </div>
                      </div>
                    ) : (
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            {contractRateHeaders.map((item, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="text-center py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {item.field}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredPolicies.map((item, index) => {
                            const rowModalInformation =
                              contractModalDetails?.reduce((acc, detail) => {
                                if (
                                  detail.contractId === item.docId &&
                                  detail.modalContent?.highlights?.length > 0
                                ) {
                                  acc[detail.attributeName] =
                                    detail.modalContent.highlights;
                                }
                                return acc;
                              }, {});
                            const contract = contracts?.find(
                              (contract) => contract.id === item.contract_id
                            );

                            const filePath = contract
                              ? contract?.file_path
                              : null;
                            const contractName = contract
                              ? contract?.file_name
                              : null;

                            const currentYear = new Date().getFullYear();
                            const startYear = new Date(
                              item.rateEffectiveStartDate
                            ).getFullYear();
                            const endYear = item.rateEffectiveEndDate
                              ? new Date(
                                  item.rateEffectiveEndDate
                                ).getFullYear()
                              : null;

                            const isCurrent =
                              startYear === currentYear &&
                              (!endYear || endYear >= currentYear);

                            const payerInfo =
                              rowModalInformation?.["Payer"] || [];
                            const localityInfo =
                              rowModalInformation?.["Locality"] || [];

                            const providerTypeInfo =
                              rowModalInformation?.["Provider Type"] || [];
                            const rateInfo = rowModalInformation?.["Rate"];
                            const procedureInfo =
                              rowModalInformation?.["Procedure"] || [];
                            const rateEffectiveStartDateInfo =
                              rowModalInformation?.[
                                "Rate Effective Start Date"
                              ] || [];
                            const rateEffectiveEndDateInfo =
                              rowModalInformation?.[
                                "Rate Effective Start Date"
                              ] || [];

                            return (
                              <tr
                                key={item.id}
                                className={
                                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }
                              >
                                <td
                                  onClick={() => {
                                    if (item.procedure) {
                                      setStartPage(2);
                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...procedureInfo,
                                          ],
                                        },
                                      ]);
                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className=" cursor-pointer whitespace-pre-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                                >
                                  {item.procedure}
                                </td>
                                <td
                                  onClick={() => {
                                    if (
                                      item.payer &&
                                      userInformation?.organizationId !== 2
                                    ) {
                                      setStartPage(2);
                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...payerInfo,
                                          ],
                                          // modalHighlights: [...payerInfo],
                                        },
                                      ]);
                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className=" pr-3  underline  whitespace-pre-wrap  truncate py-4 text-sm text-center text-gray-500"
                                >
                                  <p className="truncate">{item.payer}</p>
                                </td>

                                <td
                                  onClick={() => {
                                    if (item.locality) {
                                      setStartPage(2);
                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...localityInfo,
                                          ],
                                        },
                                      ]);
                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className="pr-3 underline cursor-pointer py-4  whitespace-pre-wrap text-sm text-center text-gray-500 max-w-xs truncate"
                                >
                                  {item.locality}
                                </td>

                                {/* <td
                                  onClick={() => {
                                    if (item.providerType) {
                                      setStartPage(2);

                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...providerTypeInfo,
                                          ],
                                        },
                                      ]);
                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className="min-w-[150px] whitespace-pre-wrap underline cursor-pointer pr-3 py-4 text-sm text-center text-gray-500"
                                >
                                  {item.providerType || item.provider_type}
                                </td> */}

                                {/* <td
                                  onClick={() => {
                                    if (item.feeSchedule) {
                                      setStartPage(2);

                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...providerTypeInfo,
                                          ],
                                        },
                                      ]);

                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className="min-w-[150px] whitespace-pre-wrap underline cursor-pointer pr-3 py-4 text-sm text-center text-gray-500"
                                >
                                  {item.feeSchedule || item.fee_schedule_id}
                                </td> */}

                                <td
                                  onClick={() => {
                                    if (item.rateEffectiveStartDate) {
                                      setStartPage(2);

                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...rateEffectiveStartDateInfo,
                                          ],
                                        },
                                      ]);

                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className=" whitespace-wrap text-nowrap cursor-pointer pr-3 py-4 text-sm text-center text-gray-500"
                                >
                                  <span
                                    className={classNames(
                                      "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                      isCurrent
                                        ? "bg-green-100 text-green-800"
                                        : "bg-gray-100 text-gray-800"
                                    )}
                                  >
                                    {item.rateEffectiveStartDate ||
                                      formatDate(
                                        item.rate_effective_start_date
                                      ) ||
                                      "-"}
                                  </span>
                                </td>

                                <td
                                  onClick={() => {
                                    if (item.rateEffectiveEndDate) {
                                      setStartPage(2);

                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...rateEffectiveEndDateInfo,
                                          ],
                                        },
                                      ]);

                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className=" whitespace-wrap text-nowrap cursor-pointer pr-3 py-4 text-sm text-center text-gray-500"
                                >
                                  <span
                                    className={classNames(
                                      "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                      isCurrent
                                        ? "bg-green-100 text-green-800"
                                        : "bg-gray-100 text-gray-800"
                                    )}
                                  >
                                    {item.rateEffectiveEndDate ||
                                      formatDate(
                                        item.rate_effective_end_date
                                      ) ||
                                      "-"}
                                  </span>
                                </td>

                                <td
                                  onClick={() => {
                                    if (item.rate) {
                                      setStartPage(1);
                                      setContractModalPdfDetails();
                                      setContractModalPdfDetails([
                                        {
                                          filePath:
                                            item.file_path ||
                                            DEFAULT_DEMO_CONTRACT_FILE_PATH,
                                          modalHighlights: item.highlights || [
                                            ...rateInfo,
                                          ],
                                        },
                                      ]);
                                      setOpenPdfViewer(true);
                                    }
                                  }}
                                  className=" whitespace-pre-wrap underline cursor-pointer  pr-3 py-4 text-sm text-center text-gray-500"
                                >
                                  <span
                                    className={classNames(
                                      "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                      isCurrent
                                        ? "bg-green-100 text-green-800"
                                        : "bg-gray-100 text-gray-800"
                                    )}
                                  >
                                    {(() => {
                                      let rate = item.rate;
                                      if (typeof rate === "string") {
                                        rate = rate.replace("$", "").trim();
                                      }
                                      rate = parseFloat(rate);
                                      return isNaN(rate)
                                        ? "-"
                                        : `$${rate.toFixed(2)}`;
                                    })()}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-b-lg">
              <div className="flex flex-1 justify-between sm:hidden">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={isNextDisabled}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing page{" "}
                    <span className="font-medium">{currentPage}</span>
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={isNextDisabled}
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractedRatesPage;
