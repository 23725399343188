import React, { createContext, usecontext, useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import axios from "axios";
import { useUserContext } from "./user-context";
import { RECON_OS_SERVER_URI } from "@/lib/services";
import { db } from "../firebase-config";
import { excludedFields as insightExcludedFields } from "../lib/constants";
import { useLocation } from "react-router-dom";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const { userInformation } = useUserContext();
  const location = useLocation();
  const isInsightPage = location.pathname.includes("/insight-engine");
  const isShareInsightPage = location.pathname.includes(
    "/insight-engine/share"
  );
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [policyItem, setPolicyItem] = useState(false);
  const [startPage, setStartPage] = useState(1);
  const [type, setType] = useState("BSBC-1");
  const [contractModalPdfDetails, setContractModalPdfDetails] = useState();
  const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
  const [openNewTemplateModal, setOpenNewTemplateModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [details, setDetails] = useState();
  const [filterOptions, setFilterOptions] = useState({});
  const [activeFilters, setActiveFilters] = useState(() => {
    const insightAppliedFilters = localStorage.getItem(
      "insight_applied_filters"
    );
    if (isInsightPage && insightAppliedFilters) {
      const insightFilters = JSON.parse(insightAppliedFilters);
      return insightFilters;
    }
    return {
      plan: [{ value: 1, label: "Empire HMO Plan" }],
    };
  });

  const [openSubscriptionStatusModal, setOpenSubscriptionStatusModal] =
    useState(false);

  const [localityInformationModal, setLocalityInformationModal] =
    useState(false);
  const [localityInformation, setLocalityInformation] = useState({
    locality: "",
    description: "",
  });

  const [openClaimDisputionModal, setOpenClaimDisputionModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState([]);
  // CPT CODE SEARCH
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [cptSearchData, setCptSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openShareInsightModal, setOpenShareInsightModal] = useState(false);
  const [openSharedLinkListModal, setOpenSharedLinkListModal] = useState(false);

  const defaultFilters = {
    searchTerm: "",
    startDate: new Date().toISOString().split("T")[0],
    endDate: "",
    plan: "",
    providerId: "",
    providerType: "",
    locality: "",
    carrier: "",
    payer: "",
    clause: "",
    feeSchedule: "",
  };

  const [filters, setFilters] = useState(() => {
    const storedFilters = localStorage.getItem("insight_filters");
    const insightAppliedFilters = localStorage.getItem(
      "insight_applied_filters"
    );
    if (storedFilters && isShareInsightPage) {
      console.log("THERE ARE STORED FILTERS");
      return JSON.parse(storedFilters);
    } else if (insightAppliedFilters && isInsightPage) {
      console.log("THERE ARE INSIGHT APPLIED FILTERS");
      const parsedInsightFilters = JSON.parse(insightAppliedFilters);
      return {
        ...parsedInsightFilters,
        searchTerm: defaultFilters.searchTerm,
        startDate: defaultFilters.startDate,
      };
    }

    console.log("THERE ARE NO STORED FILTERS");
    return defaultFilters;
  });

  const [page, setPage] = useState(1);

  const [excludedFields, setExcludedFields] = useState(() => {
    const excludedColumns = localStorage.getItem("insight_excluded_fields");
    if (excludedColumns) {
      console.log("THERE ARE STORED FILTERS");
      return JSON.parse(excludedColumns);
    }
    return insightExcludedFields;
  });

  const [insightColumns, setInsightColumns] = useState([]);
  const [showHideColumnModal, setHideColumnModal] = useState(false);

  const handleClaimClick = (data) => {
    console.log("THE CLAIM DATA INFORMATION ====>", data);
    setSelectedClaim(data);
    setOpenClaimDisputionModal(true);
  };

  console.log("Filters", filters);
  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD
    }
    return date; // Return as-is if it's already a string or undefined
  };

  const fetchCPTCodeData = async (
    cptCode,
    startDate = "",
    endDate = "",
    localityCode = "",
    providerType = null,
    carrier = null,
    payer = null,
    plan = null,
    clause = null,
    feeSchedule = null,
    page = 1
  ) => {
    setIsLoading(true);
    const sharedInsightOrganizationId = localStorage.getItem("organization_id");
    const organizationId =
      userInformation?.organizationId || sharedInsightOrganizationId; // Get organizationId from userInformation
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const response = await axios.post(
        `${RECON_OS_SERVER_URI}/cpt-price-search`,
        {
          cptCode,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          locality: localityCode,
          providerTypeId: providerType,
          providerType: providerType,
          carrier,
          payer,
          plan,
          clause,
          page,
          feeSchedule,
        },
        {
          params: {
            organization_id: organizationId, // Pass organizationId as a query parameter
          },
        }
      );
      const responseData = response.data;
      console.log("The response data is", responseData);

      setPagination(responseData?.pagination);
      setResults(responseData?.data);

      return responseData?.data;
    } catch (error) {
      console.log("Error fetching CPT code data:", error);
      setResults([]);
      throw error; // Propagate the error to the caller if needed
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterOptions = async (organizationId) => {
    try {
      const response = await axios.get(
        `${RECON_OS_SERVER_URI}/filter-options`,
        {
          params: {
            organization_id: organizationId,
          },
        }
      );
      setFilterOptions(response.data);

      // Get first plan if available
      const firstPlan = response.data.planOptions?.[0];
      const defaultPlan = firstPlan ? [firstPlan] : [];

      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          searchTerm: "Global",
          plan: firstPlan ? [firstPlan] : [],
        };

        // Store in localStorage
        localStorage.setItem(
          "insight_applied_filters",
          JSON.stringify(newFilters)
        );

        return newFilters;
      });

      fetchCPTCodeData(
        "",
        filters.startDate,
        filters.endDate,
        filters?.locality,
        filters?.providerType,
        filters?.carrier,
        filters?.payer,
        defaultPlan,
        filters?.clause,
        filters?.feeSchedule,
        page
      );
    } catch (error) {
      console.log("Error fetching filter options:", error);
      setFilterOptions({});
    }
  };

  const handleAddNewTaskToDb = async (phoneNumber, template, notes) => {
    try {
      const item = {
        template: template,
        phoneNumber: phoneNumber,
        notes: notes,
        progress: "In Progress",
        contactInfo: "Blue Cross of Maryland",
      };
      await addDoc(collection(db, "Phone agent"), item);
      // You might need to update state or perform other actions here
      console.log("Item added to the database");
    } catch (error) {
      console.log("Error adding document: ", error);
    }
  };

  const handleAddTemplate = async (item) => {
    try {
      await addDoc(collection(db, "template"), item);
      console.log("Template added to db");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleFilterChange = (e) => {
    let name, value;
    if (e.target && e.target.name) {
      ({ name, value } = e.target);
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    localStorage.setItem("insight_filters", JSON.stringify(newFilters));
  };

  // Change to search
  const searchCptCode = async (page = 1) => {
    console.log("Applying filters:", filters);
    setPage(page);

    if (isInsightPage) {
      localStorage.setItem("insight_applied_filters", JSON.stringify(filters));
    }
    const cptSearchTerm =
      filters.searchTerm === "Global" ? "" : filters.searchTerm;

    try {
      await fetchCPTCodeData(
        cptSearchTerm,
        filters.startDate,
        filters.endDate,
        filters?.locality,
        filters?.providerType,
        filters?.carrier,
        filters?.payer,
        filters?.plan,
        filters?.clause,
        filters?.feeSchedule,
        page
      );
    } catch (error) {
      console.log("Error fetching CPT code data:", error);
      // setResults([]);
    }

    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    // Create a function to parse query parameters and update state
    const updateStateFromQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const openNewTask = queryParams.get("openNewTaskModal") === "true";
      setOpenNewTaskModal(openNewTask);
    };

    // Call the function to update state from query parameters
    updateStateFromQueryParams();

    // Add an event listener for the 'popstate' event to handle back/forward navigation
    window.addEventListener("popstate", updateStateFromQueryParams);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", updateStateFromQueryParams);
    };
  }, []);

  useEffect(() => {
    if (userInformation?.organizationId) {
      fetchFilterOptions(userInformation?.organizationId);
    }
  }, [userInformation?.organizationId]);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    localStorage.setItem("insight_filters", JSON.stringify(newFilters));
  };

  return (
    <ModalContext.Provider
      value={{
        openPdfModal,
        setOpenPdfModal,
        openPdfViewer,
        setOpenPdfViewer,
        openPolicyModal,
        setOpenPolicyModal,
        policyItem,
        setPolicyItem,
        startPage,
        setStartPage,
        type,
        setType,
        contractModalPdfDetails,
        setContractModalPdfDetails,
        openNewTaskModal,
        setOpenNewTaskModal,
        openNewTemplateModal,
        setOpenNewTemplateModal,
        openDetailsModal,
        setOpenDetailsModal,
        openSubscriptionStatusModal,
        setOpenSubscriptionStatusModal,
        details,
        setDetails,
        handleAddNewTaskToDb,
        handleAddTemplate,
        filters,
        setFilters,
        results,
        setResults,
        page,
        setPage,
        pagination,
        setPagination,
        isFilterModalOpen,
        setIsFilterModalOpen,
        handleFilterChange,
        updateFilters,
        searchCptCode,
        filterOptions,
        setFilterOptions,
        localityInformationModal,
        setLocalityInformationModal,
        localityInformation,
        setLocalityInformation,
        openClaimDisputionModal,
        setOpenClaimDisputionModal,
        openShareInsightModal,
        setOpenShareInsightModal,
        handleClaimClick,
        selectedClaim,
        insightColumns,
        setInsightColumns,
        excludedFields,
        setExcludedFields,
        insightColumns,
        setInsightColumns,
        showHideColumnModal,
        setHideColumnModal,
        openSharedLinkListModal,
        setOpenSharedLinkListModal,
        activeFilters,
        setActiveFilters,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
