import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Trash2, Link } from "lucide-react";
import { db } from "../../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useUserContext } from "../../context/user-context";

export const ManageSharedLinksModal = ({ isOpen, setIsOpen }) => {
  const { userInformation } = useUserContext();
  const [sharedLinks, setSharedLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchSharedLinks();
    }
  }, [isOpen, userInformation]);

  const fetchSharedLinks = async () => {
    setIsLoading(true);
    setError("");
    try {
      const q = query(
        collection(db, "share_links"),
        where("user_id", "==", userInformation?.id)
      );
      const querySnapshot = await getDocs(q);
      const links = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSharedLinks(links);
    } catch (error) {
      console.error("Error fetching shared links: ", error);
      setError("Failed to load shared links. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLink = async (linkId) => {
    try {
      await deleteDoc(doc(db, "share_links", linkId));
      setSharedLinks(sharedLinks.filter((link) => link.id !== linkId));
    } catch (error) {
      console.error("Error deleting shared link: ", error);
      setError("Failed to delete the link. Please try again.");
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900 mb-4"
                >
                  Manage Shared Links
                </Dialog.Title>
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="mt-2">
                  {isLoading ? (
                    <div className="text-center">
                      <svg
                        className="animate-spin h-5 w-5 mx-auto text-indigo-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <p className="mt-2 text-sm text-gray-500">
                        Loading shared links...
                      </p>
                    </div>
                  ) : error ? (
                    <p className="text-sm text-red-600">{error}</p>
                  ) : sharedLinks.length === 0 ? (
                    <p className="text-sm text-gray-500">
                      No shared links found.
                    </p>
                  ) : (
                    <ul className="space-y-4">
                      {sharedLinks.map((link) => (
                        <li
                          key={link.id}
                          className="bg-gray-50 p-4 rounded-md flex items-center justify-between"
                        >
                          <div className="flex items-center space-x-3">
                            <Link className="h-5 w-5 text-indigo-600" />
                            <span className="text-sm font-medium text-gray-900 truncate max-w-[200px]">
                              {link.link}
                            </span>
                          </div>
                          <button
                            onClick={() => handleDeleteLink(link.id)}
                            className="p-1 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          >
                            <Trash2 className="h-5 w-5 text-red-500" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
