import React, { useState, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useNavigate } from "react-router-dom";

const AuditStatusCell = (props) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Not Started":
        return "bg-gray-400";
      case "In Progress":
        return "bg-yellow-400";
      case "Completed":
        return "bg-green-400";
      default:
        return "bg-gray-400";
    }
  };

  return (
    <div className="flex items-center">
      <div
        className={`w-2 h-2 rounded-full mr-2 ${getStatusColor(props.value)}`}
      ></div>
      <span className="text-[14px]">{props.value}</span>
    </div>
  );
};

const ReviewStatusCell = (props) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case "Needs Review":
        return "text-orange-500 border-orange-500";
      case "Reviewed":
        return "text-green-500 border-green-500";
      default:
        return "text-gray-500 border-gray-500";
    }
  };

  return (
    <div
      className={`border rounded px-1 py-0.5 text-xs sm:text-sm inline-block ${getStatusStyle(
        props.value
      )}`}
    >
      {props.value}
    </div>
  );
};

const ScoreCell = (props) => {
  return <span className="text-[16px]">{props.value}%</span>;
};

const AuditResult = () => {
  const navigate = useNavigate();
  const [rowData] = useState([
    {
      uploadDate: "Aug 29, 2023 at 05:43 am",
      provider: "Michael Williams",
      score: 69,
      auditStatus: "Completed",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Aug 29, 2023 at 05:43 am",
      provider: "Sarah Brown",
      score: 75,
      auditStatus: "Completed",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Aug 31, 2023 at 03:43 pm",
      provider: "Emily Johnson",
      score: 94,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 01, 2023 at 10:15 am",
      provider: "David Lee",
      score: 88,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 01, 2023 at 02:30 pm",
      provider: "Lisa Chen",
      score: 72,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 02, 2023 at 09:00 am",
      provider: "Robert Taylor",
      score: 81,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 02, 2023 at 01:45 pm",
      provider: "Jessica Martinez",
      score: 93,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 03, 2023 at 11:20 am",
      provider: "William Anderson",
      score: 67,
      auditStatus: "Not Started",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 03, 2023 at 04:10 pm",
      provider: "Jennifer Wilson",
      score: 85,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 04, 2023 at 08:30 am",
      provider: "Daniel Thompson",
      score: 79,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 04, 2023 at 03:00 pm",
      provider: "Maria Garcia",
      score: 91,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 05, 2023 at 10:45 am",
      provider: "Christopher Lewis",
      score: 76,
      auditStatus: "Completed",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 05, 2023 at 02:15 pm",
      provider: "Elizabeth Clark",
      score: 88,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 06, 2023 at 09:30 am",
      provider: "Kevin Rodriguez",
      score: 70,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 06, 2023 at 01:00 pm",
      provider: "Amanda White",
      score: 95,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 07, 2023 at 11:00 am",
      provider: "Thomas Johnson",
      score: 82,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 07, 2023 at 04:30 pm",
      provider: "Sophia Lee",
      score: 78,
      auditStatus: "Not Started",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 08, 2023 at 08:45 am",
      provider: "Ryan Miller",
      score: 89,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 08, 2023 at 02:45 pm",
      provider: "Olivia Davis",
      score: 73,
      auditStatus: "In Progress",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 09, 2023 at 10:00 am",
      provider: "Ethan Wilson",
      score: 86,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 09, 2023 at 03:30 pm",
      provider: "Emma Thompson",
      score: 92,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
    {
      uploadDate: "Sep 10, 2023 at 09:15 am",
      provider: "Noah Martinez",
      score: 77,
      auditStatus: "Not Started",
      reviewStatus: "Needs Review",
    },
    {
      uploadDate: "Sep 10, 2023 at 01:30 pm",
      provider: "Ava Anderson",
      score: 84,
      auditStatus: "Completed",
      reviewStatus: "Reviewed",
    },
  ]);

  const [columnDefs] = useState([
    {
      field: "uploadDate",
      headerName: "Upload Date",
      sort: "desc",
      minWidth: 150,
    },
    { field: "provider", headerName: "Provider", minWidth: 120 },
    {
      field: "score",
      headerName: "Score",
      cellRenderer: ScoreCell,
      minWidth: 80,
    },
    {
      field: "auditStatus",
      headerName: "Audit Status",
      cellRenderer: AuditStatusCell,
      minWidth: 120,
    },
    {
      field: "reviewStatus",
      headerName: "Review Status",
      cellRenderer: ReviewStatusCell,
      minWidth: 120,
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <button
          className="bg-purple-600 text-white px-2 py-1 rounded text-xs sm:text-sm"
          onClick={() => navigate(`/review-audit/${params.data.provider}`)}
        >
          Review Audit
        </button>
      ),
      minWidth: 100,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="p-2 sm:p-4 space-y-2 sm:space-y-4">
        <h1 className="text-xl sm:text-2xl font-bold">Audit Results</h1>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-2 sm:p-4">
          <p className="text-xs sm:text-sm">
            Results can take up to 6 hours in the queue to be processed in
            sandbox. You will receive an email notification when processing
            completes. In production, results process in 5-7 minutes. Reach out
            to chester@recon.health or steven@recon.health with any questions.
          </p>
        </div>
      </div>
      <div className="flex-grow p-2 sm:p-4">
        <div
          className="ag-theme-quartz w-full h-full"
          style={{
            "--ag-row-border-width": "1px",
            "--ag-row-border-color": "#e2e8f0",
            "--ag-cell-horizontal-border": "solid 1px #e2e8f0",
            "--ag-font-size": "14px",
            "--ag-header-font-size": "18px",
          }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            domLayout="normal"
            suppressColumnVirtualisation={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditResult;
