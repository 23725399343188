import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

const UseCaseCard = ({
  title,
  subtitle,
  features,
  customText,
  bgColor,
  textColor,
}) => (
  <div className={`rounded-lg shadow-lg  flex flex-col h-full`}>
    <div className="mb-4">
      <div className={` flex justify-center flex-row `}>
        <div className={`${bgColor} p-2 rounded-lg`}>
          <h3 className={`${textColor} text-sm font-medium text-center`}>
            {subtitle}
          </h3>
        </div>
      </div>
      <h2 className="text-2xl font-bold mt-1 text-center">{title}</h2>
    </div>
    <div className={`${bgColor} p-6 h-full`}>
      <ul className="space-y-2 mb-4 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <CheckIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
            <span className="text-sm">{feature}</span>
          </li>
        ))}
      </ul>
      <p className="text-sm italic mt-auto font-bold">{customText}</p>
    </div>
  </div>
);

const UseCasesPage = () => {
  const useCases = [
    {
      title: "Clinical QA",
      subtitle: "Clinical Quality Review",
      features: [
        "AI flags clinical anomalies in unstructured text for human review",
        "Suggests corrections to provider",
        "Results in 5 minutes",
        "Custom screening criteria",
      ],
      customText:
        '"Was the patient\'s chief complaint addressed in the documentation?"',
      bgColor: "bg-green-50",
      textColor: "text-green-600",
    },
    {
      title: "Coding",
      subtitle: "CPT Coding Review",
      features: [
        "AI flags coding errors",
        "Suggests opportunities for increasing/decreasing code level",
        "Results in 5 minutes",
        "Custom screening criteria",
      ],
      customText:
        '"Was 90837 the proper code for this visit? If not, what should be?"',
      bgColor: "bg-red-50",
      textColor: "text-red-600",
    },
    {
      title: "Billing",
      subtitle: "Pre-Claim Submission Review",
      features: [
        "AI flags potential denials prior to claims submission",
        "Suggests corrections to ops team",
        "Results in 5 minutes",
        "Custom screening criteria",
      ],
      customText:
        '"Were CPT code and diagnosis code congruent in the session?"',
      bgColor: "bg-blue-50",
      textColor: "text-blue-600",
    },
    {
      title: "Data Science",
      subtitle: "Trend Data",
      features: [
        "AI tracks population statistics over time",
        "Analyzes data points from unstructured text",
        "Filter data from any time period",
      ],
      customText: "",
      bgColor: "bg-gray-50",
      textColor: "text-gray-600",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Use Cases</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {useCases.map((useCase, index) => (
          <UseCaseCard key={index} {...useCase} />
        ))}
      </div>
    </div>
  );
};

export default UseCasesPage;
