import React, { useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  XMarkIcon,
  HomeIcon,
  PhoneIcon,
  PencilIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

export const ClaimDetailsModal = ({ open, setOpen, claimDetails }) => {
  const [activeTab, setActiveTab] = useState("Claim Overview");
  const [appealText, setAppealText] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const appealRef = useRef(null);

  const tabs = [
    { name: "Claim Overview", icon: HomeIcon },
    { name: "Appeal Writer", icon: PencilIcon },
    { name: "Phone Payer", icon: PhoneIcon },
  ];

  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const generateAppeal = async () => {
    setIsGenerating(true);
    setAppealText("");

    const appeal = claimDetails?.appeal;


    const chars = appeal.split("");
    for (let i = 0; i < chars.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 15)); // Adjust timing as needed
      setAppealText((prev) => prev + chars[i]);
      if (appealRef.current) {
        appealRef.current.scrollTop = appealRef.current.scrollHeight;
      }
    }
    setIsGenerating(false);
  };

  useEffect(() => {
    if (activeTab === "Appeal Writer" && !appealText) {
      generateAppeal();
    }
  }, [activeTab]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 text-left shadow-xl transition-all sm:my-8 w-full max-w-[98vw] sm:max-w-[95vw] lg:max-w-[90vw] h-[90vh]">
                <div className="bg-white h-full flex flex-col">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 border-b-2 py-3 px-4 shadow-md bg-white text-gray-900 flex justify-between items-center"
                  >
                    <div className="flex items-center">
                      <span className="mr-4">{`Disputing Claim: #${
                        claimDetails?.claims?.claimId || "N/A"
                      }`}</span>
                      <div className="flex space-x-2">
                        {tabs.map((tab) => (
                          <p
                            key={tab.name}
                            onClick={() => {
                              if (tab.name === "Phone Payer") {
                                window.open(
                                  `${
                                    window.location.origin
                                  }/ai-phone-agent?openNewTaskModal=true&showNewTask=true&phoneNumber=123-456-7890&template=${`Claim Appeal Status Check`}&notes=${`Please inquire about the status of our appeal for denied claim ${claimDetails?.claims?.claimId}.`}`,
                                  "_blank"
                                );
                              } else {
                                setActiveTab(tab.name);
                              }
                            }}
                            className={`flex whitespace-nowrap border-b-4 items-center space-x-1 py-1 px-2 cursor-pointer ${
                              activeTab === tab.name
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            }`}
                          >
                            <tab.icon
                              className={`h-5 w-5 ${
                                activeTab === tab.name
                                  ? "text-indigo-500"
                                  : "text-gray-500"
                              }`}
                            />
                            <span className="hidden sm:inline">{tab.name}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                    <button
                      onClick={() => setOpen(false)}
                      className="ml-4 text-gray-400 hover:text-gray-500"
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </Dialog.Title>

                  <div className="flex-grow overflow-y-auto p-4">
                    {activeTab === "Claim Overview" && (
                      <>
                        <div className="bg-purple-600 shadow-sm p-4 sm:p-6 mb-4">
                          <p className="text-white font-bold text-sm sm:text-base">
                            AI Generated Next Steps:
                          </p>
                          <p className="text-xs sm:text-sm text-white">
                            1. [Action Available] Write medical necessity appeal
                            per criteria outlined in policy MP.PA.134.
                          </p>
                        </div>

                        <div className="flex flex-col lg:flex-row gap-3">
                          <div className="w-full lg:w-1/4 bg-white shadow-md border flex flex-col p-4 rounded-md mb-4 lg:mb-0">
                            <h4 className="font-bold mb-2">Claim Details</h4>
                            <div className="grid grid-cols-2 gap-2 sm:gap-4">
                              {claimDetails?.claims &&
                                Object.entries(claimDetails.claims).map(
                                  ([key, value]) => (
                                    <React.Fragment key={key}>
                                      <p className="text-xs sm:text-sm font-semibold">
                                        {formatKey(key)}
                                      </p>
                                      <p className="text-xs sm:text-sm">
                                        {value}
                                      </p>
                                    </React.Fragment>
                                  )
                                )}
                            </div>
                          </div>

                          <div className="flex-1 border rounded-md">
                            <div className="border-b py-3 px-4 shadow-md bg-white flex items-center">
                              <DocumentTextIcon className="h-5 w-5 mr-2 text-gray-500" />
                              <p className="font-bold text-sm sm:text-base">
                                Claim Note
                              </p>
                            </div>
                            <div className="overflow-y-auto h-[calc(100%-3rem)]">
                              {claimDetails ? (
                                <div className="py-2 px-4">
                                  <p className="font-normal text-xs sm:text-sm">
                                    {claimDetails.note}
                                  </p>
                                  <p className="text-xs text-gray-500 mt-2">
                                    {claimDetails.timestamp}
                                  </p>
                                </div>
                              ) : (
                                <p className="p-4 text-gray-500 text-xs sm:text-sm">
                                  No claim note available.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {activeTab === "Appeal Writer" && (
                      <div className="flex h-full">
                        {/* Document PDF Viewer */}
                        <div className="w-1/2 pr-2">
                          <div className="bg-white shadow-md border rounded-md h-full">
                            <div className="px-4 py-2 mb-4 shadow-md">
                              <h4 className="font-bold">Document PDF</h4>
                            </div>
                            <div className="h-[calc(100%-3rem)] overflow-y-auto">
                              <iframe
                                src={claimDetails?.denialDocument}
                                className="w-full h-[100%]"
                                allow="autoplay"
                              ></iframe>
                              {/* <PDFViewer pdfUrl={claimDetails.pdfUrl} /> */}
                            </div>
                          </div>
                        </div>

                        {/* AI Generated Appeal */}
                        <div className="w-1/2 pl-2">
                          <div className="bg-white shadow-md border rounded-md h-full">
                            <div className="px-4 py-2 mb-4 shadow-md flex flex-row items-center justify-between">
                              <h4 className="font-bold">AI Generated Appeal</h4>
                              {isGenerating && (
                                <span className="text-sm text-gray-500 animate-pulse">
                                  Generating...
                                </span>
                              )}
                            </div>
                            <div
                              ref={appealRef}
                              className="px-4 h-[calc(100%-3rem)] overflow-y-auto"
                            >
                              <pre className="whitespace-pre-wrap text-sm font-mono">
                                {appealText}
                              </pre>
                              {isGenerating && (
                                <span className="inline-block w-2 h-4 bg-black animate-blink"></span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeTab === "Phone Payer" && (
                      <div className="bg-white shadow-md border rounded-md p-4">
                        <h4 className="font-bold mb-2">
                          Phone Payer Assistant
                        </h4>
                        <p className="text-sm">
                          Phone payer assistance coming soon...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
