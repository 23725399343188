import React, { useState, useContext, useMemo } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  DocumentTextIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { ModalContext } from "../../context/modal-context";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { denialCohorts, sampleClaimDetails } from "../../lib/dummy-data";

const tabs = [
  { name: "Stats", href: "#", current: true },
  { name: "Claims", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GuardianHomePage = () => {
  const { handleClaimClick } = useContext(ModalContext);

  const [activeItem, setActiveItem] = useState(denialCohorts[0]);
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [cohortsExpanded, setCohortsExpanded] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "Claims") {
      // handleClaimClick();
    }
  };

  const handleCohortClick = (cohort) => {
    setActiveItem(cohort);
    setCohortsExpanded(false);
  };

  const series = [
    {
      dataKey: "Aetna",
      label: "Aetna",
      valueFormatter: (value) => `$${value}`,
    },
    {
      dataKey: "BlueCross",
      label: "Blue Cross",
      valueFormatter: (value) => `$${value}`,
    },
    {
      dataKey: "Cigna",
      label: "Cigna",
      valueFormatter: (value) => `$${value}`,
    },
    {
      dataKey: "UPMCHealthPlan",
      label: "UPMC Health Plan",
      valueFormatter: (value) => `$${value}`,
    },
  ];

  // AG Grid column definitions
  const columnDefs = useMemo(
    () => [
      {
        headerName: "Claim ID",
        field: "claims.claimId",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Patient Name",
        field: "claims.patientName",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Payer Name",
        field: "claims.payerName",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Submission Time",
        field: "claims.submissionTime",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Status",
        field: "claims.claimStatus",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Diagnosis Code",
        field: "claims.diagnosisCode",
        sortable: true,
        filter: true,
      },
      { headerName: "Note", field: "note", sortable: true, filter: true },
    ],
    []
  );

  // AG Grid default column definitions
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      resizable: true,
    }),
    []
  );

  // AG Grid row click handler
  const onRowClicked = (event) => {
    handleClaimClick(event.data);
  };
  return (
    <div className="bg-gray-100 p-2 sm:p-4 md:p-6 h-screen flex flex-col overflow-hidden">
      <div className="flex flex-col lg:flex-row flex-grow overflow-hidden">
        {/* Denial Cohorts - Top on mobile and tablet, Sidebar on desktop */}
        <div className="w-full lg:w-1/4 xl:w-1/5 bg-white shadow-lg flex flex-col mb-4 lg:mb-0 lg:mr-4">
          <div
            className="px-4 py-3 border-b shadow-lg flex justify-between items-center cursor-pointer lg:cursor-default"
            onClick={() => setCohortsExpanded(!cohortsExpanded)}
          >
            <h2 className="text-lg font-bold">Denial Cohorts</h2>
            <span className="lg:hidden">
              {cohortsExpanded ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </span>
          </div>

          <div
            className={`${
              cohortsExpanded ? "max-h-96" : "max-h-0"
            } lg:max-h-full overflow-y-auto transition-all duration-300 ease-in-out`}
          >
            {denialCohorts.map((cohort, index) => (
              <div
                key={index}
                className={`py-2 px-4 rounded cursor-pointer border-b ${
                  activeItem.name === cohort.name ? "bg-blue-50" : ""
                }`}
                onClick={() => handleCohortClick(cohort)}
              >
                <div className="flex items-center">
                  <DocumentTextIcon className="h-5 w-5 mr-2 text-blue-600" />
                  <p className="font-bold text-sm">{cohort.name}</p>
                </div>
                <p className="font-normal text-sm">
                  Number of Claims -{" "}
                  <span className="font-bold">{cohort.claims}</span>
                </p>
                <p className="font-normal text-sm">
                  Sum of Charge Amount -{" "}
                  <span className="font-bold">
                    ${cohort.amount.toLocaleString()}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-y-auto">
          {/* Authorization Denial Header with Tabs */}
          <div className="bg-white p-4 rounded-lg shadow mb-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <h2 className="text-xl font-bold mb-2 sm:mb-0">
                {activeItem.name}
              </h2>
              <div>
                <nav className="flex space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      onClick={() => handleTabClick(tab.name)}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium"
                      )}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {activeTab === "Stats" ? (
            <>
              {" "}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <div className="bg-white p-4 rounded-lg shadow">
                  <h3 className="font-bold mb-2">Outstanding Denied A/R</h3>
                  <p className="text-2xl sm:text-3xl font-bold text-green-600">
                    ${activeItem.amount.toLocaleString()}
                  </p>
                </div>

                <div className="bg-white p-4 rounded-lg shadow">
                  <h3 className="font-bold mb-2">Current Denial Backlog</h3>
                  <p className="text-2xl sm:text-3xl font-bold text-blue-600">
                    {activeItem.claims} Claims
                  </p>
                </div>

                <div className="bg-white p-4 rounded-lg shadow">
                  <h3 className="font-bold mb-2">Average Claim Age</h3>
                  <p className="text-2xl sm:text-3xl font-bold text-purple-600">
                    {activeItem.averageClaimAge.toFixed(2)} Months
                  </p>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow mb-4">
                <h3 className="font-bold mb-4">
                  Outstanding A/R vs. Claim Submission Date
                </h3>
                <div className="w-full overflow-x-auto">
                  <BarChart
                    className="w-[100%]"
                    dataset={activeItem.chartData}
                    xAxis={[{ scaleType: "band", dataKey: "month" }]}
                    series={series}
                    height={400}
                    margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="bg-white p-4 rounded-lg shadow mb-4">
              <h3 className="font-bold mb-4">Claims Details</h3>
              <div className="ag-theme-quartz w-full h-[600px]">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={sampleClaimDetails}
                  defaultColDef={defaultColDef}
                  onRowClicked={onRowClicked}
                  pagination={true}
                  paginationPageSize={10}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuardianHomePage;
