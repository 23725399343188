import { useState, useEffect } from "react";
import {
  query,
  where,
  getDocs,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { handleRedirection } from "../lib/helper-functions";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config"; // Import your Firebase config

export const useUser = () => {
  const auth = getAuth();

  const navigate = useNavigate();
  const [userInformation, setUserInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async (user) => {
      try {
        // Query the users collection to get user information
        const q = query(collection(db, "users"), where("id", "==", user?.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = {
            ...querySnapshot.docs[0].data(),
            docId: querySnapshot.docs[0].id,
          };

          // Query the organization_users collection to get organization IDs
          const orgQuery = query(
            collection(db, "organization_users"),
            where("user_id", "==", user?.uid)
          );
          const orgSnapshot = await getDocs(orgQuery);

          if (!orgSnapshot.empty) {
            const userCurrentOrganzation = localStorage.getItem(
              "current_organization"
            );

            let userWithOrg;

            if (userCurrentOrganzation) {
              // Set the user information in the state
              userWithOrg = {
                ...userData,
                organizationId: Number(userCurrentOrganzation),
              };
              setUserInformation(userWithOrg);
            } else {
              console.log("OUTSIDE THE USERINFORMATION");
              // Get the first organizationId from the results
              const organizationId = orgSnapshot.docs[0].data().organization_id;

              // Set organizationId in local storage
              localStorage.setItem("current_organization", organizationId);
              // Add organizationId to the user data

              userWithOrg = { ...userData, organizationId: organizationId };
              setUserInformation(userWithOrg);
            }
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      // alert("USER");
      if (user) {
        fetchUser(user);
      } else {
        setUserInformation(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const updateUserOrganizationId = (newOrganizationId) => {
    if (!userInformation || !userInformation.docId) {
      setError("No user to update");
      return;
    }
    localStorage.removeItem("insight_filters");
    localStorage.removeItem("insight_applied_filters");

    try {
      setLoading(true);

      // Update organizationId in local storage
      localStorage.setItem("current_organization", newOrganizationId);

      handleRedirection(userInformation?.id, navigate, newOrganizationId);

      // Update the state with the new organizationId
      setUserInformation({
        ...userInformation,
        organizationId: newOrganizationId,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { userInformation, loading, error, updateUserOrganizationId };
};
