import React, { useContext } from "react";
import { Share2, Link } from "lucide-react";

import { ModalContext } from "../../context/modal-context";

export const ShareButton = () => {
  const { setOpenShareInsightModal, setOpenSharedLinkListModal } =
    useContext(ModalContext);

  return (
    <div className="flex flex-col space-y-2">
      <button
        onClick={() => setOpenShareInsightModal(true)}
        className="inline-flex items-center justify-center px-4 py-2 bg-violet-600 hover:bg-violet-700 rounded-lg text-white text-sm font-medium transition-colors duration-200"
      >
        <Share2 className="h-4 w-4 mr-2" />
        Generate Provider-View Link
      </button>
      {/* <button
        onClick={() => setOpenSharedLinkListModal(true)}
        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Link className="h-5 w-5 mr-2 text-gray-500" />
        Preview Previous Links
      </button> */}
    </div>
  );
};
