import React, { useRef, useState, useContext, useEffect } from "react";

import {
  Search,
  Calendar,
  Filter,
  ChevronUp,
  ChevronDown,
  Download,
  FileSpreadsheet,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import { ViewColumnsIcon } from "@heroicons/react/24/outline";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  query,
  where,
} from "firebase/firestore";

import { Dialog, Transition } from "@headlessui/react";
import { ModalContext } from "../../context/modal-context";
import { useUserContext } from "../../context/user-context";
import { truncateText } from "../../lib/helper-functions";
import { downloadExcel } from "react-export-table-to-excel";
import { ShareButton } from "../ShareButton";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { db } from "../../firebase-config";
import axios from "axios";
import {
  columnDisplayNames,
  excludedFields as defaultExcludedFields,
} from "../../lib/constants";
import { RECON_OS_SERVER_URI } from "@/lib/services";

const ActiveFilterCount = ({ filters }) => {
  const excludedFields = ["searchTerm", "endDate", "startDate"];

  const activeFilterCount = Object.entries(filters).reduce(
    (count, [key, value]) => {
      if (
        !excludedFields.includes(key) &&
        value &&
        (Array.isArray(value) ? value.length > 0 : value !== "")
      ) {
        return count + 1;
      }
      return count;
    },
    0
  );

  if (activeFilterCount === 0) return null;

  return (
    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
      {activeFilterCount} {activeFilterCount === 1 ? "filter" : "filters"}{" "}
      active
    </span>
  );
};

const PaginatedTable = ({
  columns,
  onSort,
  results,
  formatCellValue,
  handleClauseIdClick,
  handleLocalityCodeClick,
  pagination,
  isPaginationLoading,
  setIsPaginationLoading,
  isLoading,
  currentPage,
  onPageChange,
  downloadExcelFile,
}) => {
  const handlePageChange = (newPage) => {
    console.log("THE NEW PAGE", newPage);
    onPageChange({ page: newPage, pageSize: pagination?.pageSize });
  };

  // Added helper to check if we're on the first page
  const isFirstPage = currentPage === 1;

  // Added helper to check if we're on the last page
  const isLastPage = currentPage === pagination?.totalPages;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="p-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <p className="text-sm text-gray-600">
            About {pagination?.totalItems?.toLocaleString() || results?.length}{" "}
            results
          </p>
        </div>
        <button
          onClick={downloadExcelFile}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <FileSpreadsheet className="h-5 w-5 mr-2" />
          Download Excel
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => onSort(column.key)}
                >
                  <div className="flex items-center">
                    {column.label}
                    {column.sortDirection && (
                      <span className="ml-1">
                        {column.sortDirection === "asc" ? (
                          <ChevronUp className="w-4 h-4" />
                        ) : (
                          <ChevronDown className="w-4 h-4" />
                        )}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-4 text-center">
                  <div className="flex justify-center">
                    <div className="loader"></div>
                  </div>
                </td>
              </tr>
            ) : (
              results.map((result, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td
                      key={`${index}-${column.key}`}
                      className={`px-6 py-4 whitespace-nowrap text-sm ${
                        column.key === "clause_id" ||
                        (column.key === "locality_code" &&
                          result[column.key]?.length > 1)
                          ? "text-blue-600 hover:text-blue-800 underline cursor-pointer"
                          : "text-gray-500"
                      }`}
                      onClick={() =>
                        column.key === "clause_id"
                          ? handleClauseIdClick(result)
                          : column.key === "locality_code" &&
                            result[column.key]?.length > 1
                          ? handleLocalityCodeClick(result)
                          : null
                      }
                    >
                      {formatCellValue(column.key, result[column.key])}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {pagination?.totalItems && (
        <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex justify-between sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">
                  {(currentPage - 1) * pagination?.pageSize + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium">
                  {Math.min(
                    currentPage * pagination?.pageSize,
                    pagination?.totalItems
                  )}
                </span>{" "}
                of <span className="font-medium">{pagination?.totalItems}</span>{" "}
                results
              </p>
            </div>
            {isPaginationLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </>
            ) : (
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => handlePageChange(1)}
                    disabled={isFirstPage}
                    className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                      isFirstPage
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500 hover:bg-gray-50"
                    }`}
                  >
                    <span className="sr-only">First</span>
                    <ChevronsLeft className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={isFirstPage}
                    className={`relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium ${
                      isFirstPage
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500 hover:bg-gray-50"
                    }`}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeft className="h-5 w-5" />
                  </button>

                  {/* Page Numbers */}
                  {[...Array(pagination?.totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    if (
                      pageNumber === 1 ||
                      pageNumber === pagination?.totalPages ||
                      (pageNumber >= currentPage - 1 &&
                        pageNumber <= currentPage + 1)
                    ) {
                      return (
                        <button
                          key={pageNumber}
                          onClick={() => handlePageChange(pageNumber)}
                          className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                            currentPage === pageNumber
                              ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                              : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                          }`}
                        >
                          {pageNumber}
                        </button>
                      );
                    } else if (
                      pageNumber === currentPage - 2 ||
                      pageNumber === currentPage + 2
                    ) {
                      return (
                        <span
                          key={pageNumber}
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                        >
                          ...
                        </span>
                      );
                    }
                    return null;
                  })}

                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={isLastPage}
                    className={`relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium ${
                      isLastPage
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500 hover:bg-gray-50"
                    }`}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handlePageChange(pagination?.totalPages)}
                    disabled={isLastPage}
                    className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                      isLastPage
                        ? "text-gray-300 cursor-not-allowed"
                        : "text-gray-500 hover:bg-gray-50"
                    }`}
                  >
                    <span className="sr-only">Last</span>
                    <ChevronsRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const InsightEngine = () => {
  const {
    filters,
    setFilters,
    results,
    setResults,
    isFilterModalOpen,
    setIsFilterModalOpen,
    handleFilterChange,
    searchCptCode: originalSearchCptCode,
    setContractModalPdfDetails,
    setOpenPdfViewer,
    localityInformationModal,
    setLocalityInformationModal,
    localityInformation,
    setLocalityInformation,
    excludedFields,
    setExcludedFields,
    insightColumns,
    setInsightColumns,
    showHideColumnModal,
    setHideColumnModal,
    activeFilters,
    page,
    setPage,
    pagination,
    setPagination,
  } = useContext(ModalContext);
  const { userInformation } = useUserContext();
  const location = useLocation();
  const isInsightPage = !location.pathname.includes("/insight-engine/share");
  const tableRef = useRef(null);
  const dropdownRef = useRef(null);

  const predefinedColumnOrder = [
    "payer_name",
    "plan_name",
    "provider_type",
    "cpt_code",
    "modifier",
    "clause_id",
    "condition_details",
    "carrier_code",
    "locality_code",
    "effective_date_start",
    "effective_date_end",
    "fee_schedule_name",
    "reimbursement_amount",
    "percentage",
    "adjusted_fee",
  ];

  const [sortColumn, setSortColumn] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [pageName, setPageName] = useState("InsightEngine");
  const [isInputDropDownOpen, setIsInputDropDownOpen] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const statusMessages = [
        "Compiling CPTs matching search",
        "Aggregating contract clauses that affect this CPT",
        "Conducting contracts analysis...",
      ];

      let currentStatusIndex = 0;

      const statusInterval = setInterval(() => {
        setLoadingStatus(statusMessages[currentStatusIndex]);
        currentStatusIndex++;

        // Loop back to the last message instead of clearing the interval
        if (currentStatusIndex >= statusMessages.length) {
          currentStatusIndex = statusMessages.length - 1; // Stay on the last message
        }
      }, 750); // Change status

      // Clear the interval when the component is unmounted or isLoading changes to false
      return () => clearInterval(statusInterval);
    }
  }, [isLoading]);

  const searchCptCode = (
    bypassValidation = false,
    page = 1,
    isPaginationRequest = false
  ) => {
    if (!bypassValidation) {
      const errorMessage = validateCptCodes(filters.searchTerm);
      if (errorMessage) {
        setSearchError(errorMessage);
        return;
      }
    }

    setSearchError("");
    if (!isPaginationRequest) {
      setIsLoading(true);
    } else {
      setIsPaginationLoading(true);
    }

    const startTime = Date.now();

    // Update the originalSearchCptCode to include pagination parameters
    originalSearchCptCode(page).finally(() => {
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;

      if (timeElapsed < 3000) {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000 - timeElapsed);
      } else {
        setIsLoading(false);

        if (isPaginationRequest) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        setIsPaginationLoading(false);
      }
    });
  };

  const handlePageChange = ({ page, pageSize }) => {
    // Trigger search with new pagination state
    searchCptCode(true, page, true);
  };

  // New useEffect for automatic search on shared insight pages
  useEffect(() => {
    if (!isInsightPage && filters.searchTerm) {
      searchCptCode();
    }
  }, [isInsightPage]);

  const validateCptCodes = (codes) => {
    // don't run checks on global
    if (codes.toLowerCase().includes('global')) {
      return ""
    }

    const codeArray = codes.split(",").map((code) => code.trim());
    for (let code of codeArray) {
      if (!code) {
        return "CPT code cannot be empty.";
      }
      if (code.length !== 5) {
        return `Invalid CPT code: ${code}. CPT code must be 5 characters long.`;
      }

      const cptRegex = /^[0-9]{5}$/;
      const hcpcsRegex = /^[A-Za-z][0-9]{4}$/;
      // If none of the above checks pass, return a generic error message
      // return "Invalid CPT code format. Please enter a valid code.";
      if (!cptRegex.test(code) && !hcpcsRegex.test(code)) {
        return `Invalid CPT code format: ${code}. Please enter a valid code.`;
      }
    }

    return "";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchCptCode();
    }
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }

    const sortedResults = [...results].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    setResults(sortedResults);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/(\d+)(?:st|nd|rd|th)/, (_, day) => {
        const suffix =
          ["th", "st", "nd", "rd"][day > 3 && day < 21 ? 0 : day % 10] || "th";
        return `${day}${suffix}`;
      });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const formatCellValue = (key, value) => {
    if (key === "effective_date_start" || key === "effective_date_end") {
      return value ? formatDate(value) : "-";
    }
    if (key === "reimbursement_amount" || key === "adjusted_fee") {
      return formatCurrency(value);
    }
    if (key === "percentage" || key.toLowerCase().includes("percent")) {
      return value !== null && value !== undefined
        ? `${(value * 100).toFixed(1)}%`
        : "-";
    }
    if (key === "condition_details") {
      return truncateText(value);
    }
    if (!value) {
      return "-";
    }
    return value;
  };

  const handleClauseIdClick = (item) => {
    setContractModalPdfDetails([
      {
        filePath: item.s3_file_path,
        modalHighlights: [
          {
            page: item.page_number,
            bbox: item.bbox_coordinates,
          },
        ],
      },
    ]);
    setOpenPdfViewer(true);
  };

  const handleLocalityCodeClick = (item) => {
    setLocalityInformation({
      locality: item.locality_code,
      description: item.locality_description,
    });
    setLocalityInformationModal(true);
  };

  const handleAllCptsClick = () => {
    setPage(1);
    setFilters((prev) => ({ ...prev, searchTerm: "Global" }));
    setIsInputDropDownOpen(false);
    searchCptCode(true); // Pass true to bypass validation
  };

  const generateTableColumns = () => {
    if (results.length === 0) return [];

    // Create an object to keep track of columns with values
    const columnHasValue = {};

    // Initialize all columns to false (no values)
    Object.keys(results[0])
      .filter((key) => !excludedFields.includes(key))
      .forEach((key) => {
        columnHasValue[key] = false;
      });

    // Check each result to see if any column has a value
    results.forEach((result) => {
      Object.keys(result).forEach((key) => {
        if (
          result[key] !== null &&
          result[key] !== undefined &&
          !excludedFields.includes(key)
        ) {
          columnHasValue[key] = true;
        }
      });
    });

    // Create columns for predefined columns that have values
    const predefinedColumnsWithValues = predefinedColumnOrder
      .filter((key) => columnHasValue[key])
      // .map((key) => ({
      //   key: key,
      //   label: key
      //     .split("_")
      //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      //     .join(" "),
      // }));
      .map((key) => ({
        key: key,
        label:
          columnDisplayNames[key] ||
          key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
      }));

    // Create columns for other columns that have values and are not predefined
    const otherColumnsWithValues = Object.keys(columnHasValue)
      .filter(
        (key) => columnHasValue[key] && !predefinedColumnOrder.includes(key)
      )
      .map((key) => ({
        key: key,
        label: key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      }));

    // Combine predefined columns with other columns, maintaining the order
    return [...predefinedColumnsWithValues, ...otherColumnsWithValues];
  };

  const tableColumns = generateTableColumns();
  const customHiddenColumnsCount = excludedFields?.filter(
    (field) => !defaultExcludedFields.includes(field)
  )?.length;

  const formatDateForCSV = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const escapeCSV = (value) => {
    if (
      typeof value === "string" &&
      (value.includes(",") || value.includes('"') || value.includes("\n"))
    ) {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  };

  const downloadExcelFile = () => {
    // Group results by CPT code
    const groupedResults = results.reduce((acc, result) => {
      const cptCode = result.cpt_code;
      if (!acc[cptCode]) {
        acc[cptCode] = [];
      }
      acc[cptCode].push(result);
      return acc;
    }, {});

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create sheets for each CPT code
    Object.entries(groupedResults).forEach(([cptCode, data]) => {
      // Prepare the data for this sheet
      const sheetData = [
        tableColumns.map((column) => column.label), // Header row
        ...data.map((row) =>
          tableColumns.map((column) =>
            formatCellValue(column.key, row[column.key])
          )
        ),
      ];

      // Create a worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, cptCode);
    });

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "InsightEngine_Results.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const fetchOrganizationName = async () => {
      if (!isInsightPage) {
        const organizationId = localStorage.getItem("organization_id");
        if (organizationId) {
          const organizationsRef = collection(db, "organizations");
          const q = query(
            organizationsRef,
            where("id", "==", parseInt(organizationId))
          );

          try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const orgDoc = querySnapshot.docs[0];
              setPageName(orgDoc.data().organizationName);
            }
          } catch (error) {
            console.error("Error fetching organization:", error);
          }
        }
      }
    };

    fetchOrganizationName();
  }, [isInsightPage]);

  console.log("THE CONTEXT PAGE", page);
  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className=" flex flex-row justify-between">
        <div>
          <h1 className="text-2xl font-semibold mb-2 px-2 bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            {pageName}
          </h1>
          <h1 className="text-lg mb-6 px-2">
            Enter a CPT code to see Prices and Contract Clauses
          </h1>
        </div>
        {isInsightPage && <ShareButton />}
      </div>

      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-2">
            <label
              htmlFor="searchTerm"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              CPT Code(s)
            </label>
            <div className="relative" ref={dropdownRef}>
              <div className="relative">
                <input
                  type="text"
                  name="searchTerm"
                  id="searchTerm"
                  value={filters.searchTerm}
                  onChange={handleFilterChange}
                  onFocus={() => setIsInputDropDownOpen(true)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter CPT code(s), separated by commas..."
                  // className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
              </div>

              {isInputDropDownOpen && filters.searchTerm.trim() === "" && (
                <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm max-h-60">
                  {filters.searchTerm.trim() === "" && (
                    <div
                      className="flex items-center px-4 py-2 text-sm text-indigo-600 hover:bg-indigo-100 cursor-pointer"
                      onClick={handleAllCptsClick}
                    >
                      <span
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                          />
                        </svg>
                      </span>
                      <span className="ml-3 block truncate">All CPTs</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {searchError && (
              <p className="mt-2 text-sm text-red-600" id="search-error">
                {searchError}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Start Date
            </label>
            <div className="relative">
              <input
                type="date"
                // disabled={true}
                name="startDate"
                id="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="block w-full pl-10 pr-3 py-2 border  disabled:text-gray-300 disabled:bg-gray-100 border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400 " />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              End Date
            </label>
            <div className="relative">
              <input
                type="date"
                name="endDate"
                // disabled={true}
                id="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="block w-full disabled:text-gray-300 disabled:bg-gray-100 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <div className="flex flex-row space-x-2">
            <div
              className={`relative ${
                !filters.searchTerm ? "hover:cursor-not-allowed" : ""
              }`}
              onPointerEnter={() => setShowTooltip(true)}
              onPointerLeave={() => setShowTooltip(false)}
            >
              <button
                type="button"
                onClick={() => filters.searchTerm && setIsFilterModalOpen(true)}
                className={`inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  !filters.searchTerm
                    ? "bg-gray-200 cursor-not-allowed text-gray-400"
                    : ""
                }`}
              >
                <Filter className="h-5 w-5 mr-2" />
                Filters
                {isInsightPage && <ActiveFilterCount filters={activeFilters} />}
              </button>
              {!filters.searchTerm && showTooltip && (
                <div className="absolute left-0 bottom-full mb-2 w-48 p-2 bg-gray-700 text-white text-xs rounded shadow">
                  Enter a search term to enable more filters
                </div>
              )}
            </div>

            {isInsightPage && (
              <button
                type="button"
                onClick={() => {
                  setInsightColumns(tableColumns);
                  setHideColumnModal(true);
                }}
                className={`inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 `}
              >
                <ViewColumnsIcon className="h-5 w-5 mr-2" />
                Columns
                {customHiddenColumnsCount > 0 && (
                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                    {customHiddenColumnsCount}{" "}
                    {customHiddenColumnsCount === 1 ? "column" : "columns"}{" "}
                    hidden
                  </span>
                )}
              </button>
            )}
          </div>

          <div>
            <button
              type="button"
              onClick={() => {
                searchCptCode(false, 1);
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Search
            </button>
            {/* {filters.searchTerm && (
          <button
            type="button"
            onClick={clearSearchResults}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-2"
          >
            Clear Search
          </button>
        )} */}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex flex-col justify-center items-center">
          <div className="loader"></div>
          <div className="mt-4 text-center">
            <p className="text-sm font-medium text-gray-700">{loadingStatus}</p>
          </div>
        </div>
      ) : results.length > 0 ? (
        <PaginatedTable
          columns={tableColumns}
          results={results}
          currentPage={page}
          onSort={handleSort}
          formatCellValue={formatCellValue}
          handleClauseIdClick={handleClauseIdClick}
          handleLocalityCodeClick={handleLocalityCodeClick}
          isLoading={isLoading}
          pagination={pagination}
          isPaginationLoading={isPaginationLoading}
          setIsPaginationLoading={setIsPaginationLoading}
          onPageChange={handlePageChange}
          downloadExcelFile={downloadExcelFile}
        />
      ) : (
        <div className="bg-gray-200 shadow sm:rounded-lg p-12 text-center h-[450px] justify-center items-center flex">
          <p className="text-gray-500 text-sm">
            To see more results, search with a new procedure code or reduce the
            filters.
          </p>
        </div>
      )}
    </div>
  );
};
