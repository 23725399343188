import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { InsightEngine } from "../components/InsightEngine";
import { ModalContext } from "../context/modal-context";

const SharedInsightPage = () => {
  const { updateFilters } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const [shareData, setShareData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const currentUrl = window.location.origin + location.pathname;
      const shareId = location.pathname.split("/").pop();

      await checkSharedLink(currentUrl, shareId);
    };

    const checkSharedLink = async (currentUrl, shareId) => {
      console.log("CURRENT URL --->", currentUrl);
      const q = query(
        collection(db, "share_links"),
        where("link", "==", currentUrl)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setError("Invalid share link");
          setIsLoading(false);
          return;
        }

        const storedAuth = localStorage.getItem(`shareAuth_${shareId}`);
        if (storedAuth) {
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }


        const doc = querySnapshot.docs[0];
        console.log("THE SHARED DATA --->", doc.data());
        const sharedData = { ...doc.data(), shareId };
        setShareData(sharedData);
        if (sharedData.filters) {
          localStorage.setItem("organization_id", 2);
          updateFilters(sharedData.filters);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error checking shared link:", error);
        setError("An error occurred. Please try again.");
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, [location]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === shareData.sharedLinkPassword) {
      localStorage.setItem(`shareAuth_${shareData.shareId}`, "true");
      setIsAuthenticated(true);
      setPasswordError(""); // Clear any existing password error
    } else {
      setPasswordError("Incorrect password"); // Set password-specific error
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (isAuthenticated) {
    const persistedFilters = localStorage.getItem("insight_filters");
    const filters = persistedFilters ? JSON.parse(persistedFilters) : null;

    console.log("THE FILTERS ====>", filters);

    return <InsightEngine />;
  }

  return (
    <div className="max-w-md mx-auto mt-10">
      <form
        onSubmit={handlePasswordSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Enter Password
          </label>
          <input
            className={`shadow appearance-none border ${
              passwordError ? "border-red-500" : ""
            } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}

            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <p className="text-red-500 text-xs italic mt-1">{passwordError}</p>
          )}
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SharedInsightPage;
