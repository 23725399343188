import { Fragment, useState, useRef, useEffect, useContext } from "react";
import {
  Dialog,
  Transition,
  Combobox,
  Listbox,
  Label,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  AdjustmentsVerticalIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Trash2 } from "lucide-react";
import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
  getFirestore,
  query,
  where,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { useUserContext } from "../../context/user-context";
import { ModalContext } from "../../context/modal-context";

const MultiselectFilter = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  onSelectOpen,
  formatLabel,
}) => {
  const [selected, setSelected] = useState(value || []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelected(value || []);
  }, [value]);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );

  const toggleAll = () => {
    const newSelected = selected.length === options.length ? [] : options;
    setSelected(newSelected);
    onChange(newSelected);
  };

  const removeOption = (optionToRemove) => {
    const newSelected = selected.filter((item) => item !== optionToRemove);
    setSelected(newSelected);
    onChange(newSelected);
  };

  const truncateLabel = (text, maxLength = 15) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleChange = (newSelected) => {
    setSelected(newSelected);
    onChange(newSelected);
  };

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={handleChange}
      multiple
      onOpen={() => onSelectOpen(true)}
      onClose={() => onSelectOpen(false)}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        <div className="flex flex-wrap gap-1 p-1 border rounded-md">
          {selected.length === options.length ? (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
              All
            </span>
          ) : (
            selected.map((item) => (
              <span
                key={item.value}
                className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
              >
                <span className="truncate max-w-[100px]" title={item.label}>
                  {truncateLabel(formatLabel ? formatLabel(item) : item.label)}
                </span>
                <button
                  type="button"
                  onClick={() => removeOption(item)}
                  className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                >
                  <span className="sr-only">Remove {item.label}</span>
                  <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </span>
            ))
          )}
          <Combobox.Input
            className="flex-1 border-0 bg-transparent p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={selected.length === 0 ? placeholder : ""}
          />
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 no-scrollbar rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
            <button
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-500"
              onClick={toggleAll}
            >
              {selected.length === options.length
                ? "Deselect All"
                : "Select All"}
            </button>
          </div>
          {filteredOptions.map((option) => (
            <Combobox.Option
              key={option.value}
              value={option}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-indigo-600 text-white" : "text-gray-900"
                }`
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {formatLabel ? formatLabel(option) : option.label}
                  </span>
                  {selected && (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? "text-white" : "text-indigo-600"
                      }`}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

const NotificationBanner = ({ message }) => (
  <div className="rounded-lg bg-indigo-50 px-4 py-2.5 ">
    <p className="text-sm font-medium text-indigo-700 text-center">{message}</p>
  </div>
);
const FilterTemplatesList = ({
  savedFilters,
  onFilterSelect,
  handleDeleteFilter,
}) => {
  const [showNotification, setShowNotification] = useState(false);

  const handleFilterSelect = (filter) => {
    onFilterSelect(filter);
  };

  return (
    <div className="space-y-4">
      {/* Explanatory Header - Now with different styling */}
      <div className="bg-gray-100 p-4 mt-2 rounded-lg">
        <h3 className="text-base font-semibold text-gray-900 flex items-center gap-2">
          <InformationCircleIcon className="h-5 w-5 text-gray-500" />
          Filter Templates
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Choose a template to quickly apply predefined filters. Selecting a
          template will replace your current filter settings.
        </p>
      </div>

      {/* Filter Templates List - Now in a white card with shadow */}
      <div className="bg-white p-4 rounded-lg ">
        <div className="flex items-center gap-2 mb-3 px-1">
          <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-500" />
          <h2 className="font-semibold text-gray-900">Available Templates</h2>
        </div>
        <ul className="space-y-2">
          {savedFilters.map((filter) => {
            return (
              <li
                key={filter.id}
                className="flex items-center justify-between p-2 hover:bg-gray-50 rounded-md"
              >
                <span className="font-medium text-gray-700">{filter.name}</span>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleFilterSelect(filter)}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Apply Template
                  </button>
                  <button
                    onClick={() => handleDeleteFilter(filter.id)}
                    className="inline-flex items-center p-2  hover:text-red-600 text-red-500 focus:outline-none"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const FilterModal = () => {
  const {
    filters,
    setFilters,
    handleFilterChange,
    isFilterModalOpen,
    setIsFilterModalOpen,
    searchCptCode,
    filterOptions,
    setActiveFilters,
  } = useContext(ModalContext);

  const { userInformation } = useUserContext();
  const [activeTab, setActiveTab] = useState("apply"); // 'apply' or 'templates'
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [saveError, setSaveError] = useState("");
  const [savedFilters, setSavedFilters] = useState([]);
  const [isLoadingSavedFilters, setIsLoadingSavedFilters] = useState(false);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selected, setSelected] = useState(null);

  const tabs = [
    { id: "apply", name: "Search Filters" },
    { id: "templates", name: "Filter Templates" },
  ];

  const handleSaveWithName = async () => {
    if (!filterName.trim()) return;

    setIsSaving(true);
    setSaveError("");

    try {
      const savedFiltersRef = collection(db, "saved_filters");

      const { searchTerm, ...filtersWithoutSearch } = filters;
      const filterData = {
        name: filterName.trim(),
        filters: filtersWithoutSearch,
        user_id: userInformation?.id,
        organization_id: userInformation?.organizationId,
      };

      await addDoc(savedFiltersRef, filterData);

      // Close dialog and reset states
      setShowSaveDialog(false);
      setFilterName("");
      setIsSaving(false);
      await fetchSavedFilters();

      // Optional: Show success message
      // You might want to add a toast notification here
    } catch (error) {
      console.log("Error saving filter:", error);
      setSaveError("Failed to save filter. Please try again.");
      setIsSaving(false);
    }
  };

  //
  const clearFilters = () => {
    const filtersToClear = [
      "providerType",
      "carrier",
      "locality",
      "payer",
      "plan",
      "clause",
      "feeSchedule",
    ];
    setSelected(null);

    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      filtersToClear.forEach((key) => {
        updatedFilters[key] = "";
      });

      localStorage.setItem(
        "insight_applied_filters",
        JSON.stringify(updatedFilters)
      );
      console.log("Filters cleared:", updatedFilters);
      setActiveFilters(updatedFilters);
      return updatedFilters;
    });
  };

  const handleFilterSelect = (selectedFilter) => {
    console.log("Filter selected:", selectedFilter);
    setSelected(selectedFilter);
    if (selectedFilter?.filters) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...selectedFilter.filters,
      }));
      setActiveFilters(selectedFilter.filters);

      setNotificationMessage(
        `${selectedFilter?.name} template applied successfully`
      );
      setShowNotification(true);

      // Hide notification after 2 seconds
      setTimeout(() => setShowNotification(false), 2000);
      setActiveTab("apply");
    }
  };

  // Add some debugging to verify data

  console.log("Filters ====>:", filters);
  const providerTypeOptions = filterOptions?.providerTypeOptions || [];
  const carrierOptions = filterOptions?.carrierCodeOptions || [];
  const localityOptions = filterOptions?.localityCodeOptions || [];
  const payerOptions = filterOptions?.payerOptions || [];
  const planOptions = filterOptions?.planOptions || [];
  const clauseOptions = filterOptions?.clauseOptions || [];
  const feeScheduleOptions = filterOptions?.feeScheduleOptions || [];

  const formatLocalityLabel = (option) => `${option.value} - ${option.label}`;

  const fetchSavedFilters = async () => {
    if (!userInformation?.id || !userInformation?.organizationId) return;

    setIsLoadingSavedFilters(true);
    try {
      const savedFiltersRef = collection(db, "saved_filters");
      const q = query(
        savedFiltersRef,
        where("user_id", "==", userInformation?.id),
        where("organization_id", "==", userInformation?.organizationId)
      );

      const querySnapshot = await getDocs(q);
      const dbSavedfilters = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("WHAT ARE THESE", dbSavedfilters);
      setSavedFilters(dbSavedfilters);
    } catch (error) {
      console.error("Error fetching saved filters:", error);
    } finally {
      setIsLoadingSavedFilters(false);
    }
  };

  const handleDeleteFilter = async (filterId) => {
    if (!userInformation?.id || !userInformation?.organizationId) return;
    try {
      // Delete the document from Firestore
      await deleteDoc(doc(db, "saved_filters", filterId));

      // Update the local state by removing the deleted filter
      setSavedFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.id !== filterId)
      );
    } catch (error) {
      console.log("Error deleting filter:", error);
    }
  };

  useEffect(() => {
    if (userInformation?.id && userInformation?.organizationId) {
      fetchSavedFilters();
    }
  }, [userInformation?.id, userInformation?.organizationId, isFilterModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <Transition appear show={isFilterModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {}} // This is the change
          // onClose={() => setIsFilterModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${
                    isSelectOpen ? "h-auto max-h-[80vh]" : ""
                  }`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-4"
                  >
                    Filters
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                    onClick={() => setIsFilterModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="border-b border-gray-200 mb-1">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          onClick={() => setActiveTab(tab.id)}
                          className={`
                            whitespace-nowrap px-1 py-4 text-sm font-medium border-b-2
                            ${
                              activeTab === tab.id
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            }
                          `}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>

                  {/* Notification */}
                  {showNotification && (
                    <NotificationBanner message={notificationMessage} />
                  )}

                  {/* Saved Filters Dropdown */}
                  {activeTab === "apply" ? (
                    <div className="flex flex-col max-h-[50vh] no-scrollbar">
                      {" "}
                      {/* Fixed height container */}
                      <div className="flex-1 overflow-y-auto pr-2 no-scrollbar">
                        {" "}
                        {/* Scrollable area */}
                        <div className="mt-4 space-y-4">
                          {[
                            {
                              name: "providerType",
                              label: "Provider Type",
                              options: providerTypeOptions,
                            },
                            {
                              name: "carrier",
                              label: "Carrier Code",
                              options: carrierOptions,
                            },
                            {
                              name: "locality",
                              label: "Locality Code",
                              options: localityOptions,
                              formatLabel: formatLocalityLabel,
                            },
                            {
                              name: "payer",
                              label: "Payer",
                              options: payerOptions,
                            },
                            {
                              name: "plan",
                              label: "Plan",
                              options: planOptions,
                            },
                            {
                              name: "clause",
                              label: "Clause Id",
                              options: clauseOptions,
                            },
                            {
                              name: "feeSchedule",
                              label: "Fee Schedule",
                              options: feeScheduleOptions,
                            },
                          ].map((field) => (
                            <div key={field.name} className="space-y-2">
                              <MultiselectFilter
                                options={field.options}
                                value={filters[field.name]}
                                onChange={(selected) =>
                                  handleFilterChange({
                                    target: {
                                      name: field.name,
                                      value: selected,
                                    },
                                  })
                                }
                                label={field.label}
                                placeholder={`Select ${field.label.toLowerCase()}`}
                                onSelectOpen={(isOpen) =>
                                  setIsSelectOpen(isOpen)
                                }
                                formatLabel={field.formatLabel}
                              />
                            </div>
                          ))}
                          <div className="pt-4 mt-4 border-t sticky bottom-0 bg-white">
                            {" "}
                            {/* Fixed footer */}
                            <div className=" flex justify-between items-center">
                              <button
                                type="button"
                                className="text-sm font-medium text-gray-600 hover:text-gray-900"
                                onClick={clearFilters}
                              >
                                Clear all
                              </button>
                              <div className="flex gap-2">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                                  onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                      setActiveFilters(filters);
                                      await searchCptCode(1);
                                    } finally {
                                      setIsLoading(false);
                                      setIsFilterModalOpen(false);
                                    }
                                  }}
                                >
                                  {isLoading ? (
                                    <>
                                      <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          className="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          strokeWidth="4"
                                        ></circle>
                                        <path
                                          className="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>
                                      Applying...
                                    </>
                                  ) : (
                                    "Apply Filter"
                                  )}
                                </button>
                                <div className="relative " ref={dropdownRef}>
                                  <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-100 focus:outline-none"
                                    onClick={() =>
                                      setIsDropdownOpen(!isDropdownOpen)
                                    }
                                  >
                                    <EllipsisVerticalIcon className="h-5 w-5" />
                                  </button>
                                  {isDropdownOpen && (
                                    <div className="absolute right-0 bottom-full mb-2 w-[19rem] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <button
                                          type="button"
                                          className="w-full text-left px-4 py-2 text-sm text-blue-500 underline hover:bg-gray-100 disabled:opacity-50"
                                          onClick={() => {
                                            setShowSaveDialog(true);
                                            setIsDropdownOpen(false);
                                          }}
                                          disabled={isSaving}
                                        >
                                          {isSaving ? (
                                            <div className="flex items-center">
                                              <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                              >
                                                <circle
                                                  className="opacity-25"
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                  stroke="currentColor"
                                                  strokeWidth="4"
                                                ></circle>
                                                <path
                                                  className="opacity-75"
                                                  fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                              </svg>
                                              Saving...
                                            </div>
                                          ) : (
                                            "Save Current Filters as a New Template"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FilterTemplatesList
                      savedFilters={savedFilters}
                      selected={selected}
                      handleDeleteFilter={handleDeleteFilter}
                      onFilterSelect={handleFilterSelect}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Save Filter Dialog */}
      <Transition appear show={showSaveDialog} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[60]"
          onClose={() => setShowSaveDialog(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Save Template
                  </Dialog.Title>
                  <div className="mt-4">
                    <input
                      type="text"
                      value={filterName}
                      onChange={(e) => setFilterName(e.target.value)}
                      placeholder="Enter filter name"
                      className="w-full rounded-md border border-gray-300 px-4 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>

                  <div className="mt-6 flex justify-end gap-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setShowSaveDialog(false);
                        setFilterName("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={handleSaveWithName}
                      disabled={!filterName.trim()}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
