export const denialCohorts = [
  {
    name: "Authorization Denial - Surgical Medical Necessity",
    claims: 54,
    amount: 1191000,
    chartData: [
      {
        month: "Jan",
        Aetna: 150,
        BlueCross: 200,
        Cigna: 180,
        UPMCHealthPlan: 100,
      },
      {
        month: "Feb",
        Aetna: 120,
        BlueCross: 180,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Mar",
        Aetna: 200,
        BlueCross: 220,
        Cigna: 190,
        UPMCHealthPlan: 110,
      },
      {
        month: "Apr",
        Aetna: 180,
        BlueCross: 190,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "May",
        Aetna: 220,
        BlueCross: 230,
        Cigna: 210,
        UPMCHealthPlan: 120,
      },
      {
        month: "Jun",
        Aetna: 190,
        BlueCross: 210,
        Cigna: 185,
        UPMCHealthPlan: 105,
      },
      {
        month: "Jul",
        Aetna: 210,
        BlueCross: 225,
        Cigna: 200,
        UPMCHealthPlan: 115,
      },
      {
        month: "Aug",
        Aetna: 230,
        BlueCross: 240,
        Cigna: 220,
        UPMCHealthPlan: 125,
      },
      {
        month: "Sep",
        Aetna: 200,
        BlueCross: 215,
        Cigna: 195,
        UPMCHealthPlan: 110,
      },
      {
        month: "Oct",
        Aetna: 190,
        BlueCross: 205,
        Cigna: 185,
        UPMCHealthPlan: 105,
      },
      {
        month: "Nov",
        Aetna: 170,
        BlueCross: 185,
        Cigna: 165,
        UPMCHealthPlan: 95,
      },
      {
        month: "Dec",
        Aetna: 160,
        BlueCross: 175,
        Cigna: 155,
        UPMCHealthPlan: 85,
      },
    ],
    averageClaimAge: 11.34,
  },
  {
    name: "Authorization Denial - Outstanding",
    claims: 57,
    amount: 1011000,
    chartData: [
      {
        month: "Jan",
        Aetna: 130,
        BlueCross: 190,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "Feb",
        Aetna: 110,
        BlueCross: 170,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "Mar",
        Aetna: 180,
        BlueCross: 210,
        Cigna: 180,
        UPMCHealthPlan: 100,
      },
      {
        month: "Apr",
        Aetna: 160,
        BlueCross: 180,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "May",
        Aetna: 200,
        BlueCross: 220,
        Cigna: 200,
        UPMCHealthPlan: 110,
      },
      {
        month: "Jun",
        Aetna: 170,
        BlueCross: 200,
        Cigna: 175,
        UPMCHealthPlan: 95,
      },
      {
        month: "Jul",
        Aetna: 190,
        BlueCross: 215,
        Cigna: 190,
        UPMCHealthPlan: 105,
      },
      {
        month: "Aug",
        Aetna: 210,
        BlueCross: 230,
        Cigna: 210,
        UPMCHealthPlan: 115,
      },
      {
        month: "Sep",
        Aetna: 180,
        BlueCross: 205,
        Cigna: 185,
        UPMCHealthPlan: 100,
      },
      {
        month: "Oct",
        Aetna: 170,
        BlueCross: 195,
        Cigna: 175,
        UPMCHealthPlan: 95,
      },
      {
        month: "Nov",
        Aetna: 150,
        BlueCross: 175,
        Cigna: 155,
        UPMCHealthPlan: 85,
      },
      {
        month: "Dec",
        Aetna: 140,
        BlueCross: 165,
        Cigna: 145,
        UPMCHealthPlan: 75,
      },
    ],
    averageClaimAge: 10.5,
  },
  {
    name: "Medicare Advantage - Inpatient Only",
    claims: 25,
    amount: 998000,
    chartData: [
      {
        month: "Jan",
        Aetna: 120,
        BlueCross: 180,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Feb",
        Aetna: 100,
        BlueCross: 160,
        Cigna: 140,
        UPMCHealthPlan: 80,
      },
      {
        month: "Mar",
        Aetna: 170,
        BlueCross: 200,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "Apr",
        Aetna: 150,
        BlueCross: 170,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "May",
        Aetna: 190,
        BlueCross: 210,
        Cigna: 190,
        UPMCHealthPlan: 105,
      },
      {
        month: "Jun",
        Aetna: 160,
        BlueCross: 190,
        Cigna: 165,
        UPMCHealthPlan: 90,
      },
      {
        month: "Jul",
        Aetna: 180,
        BlueCross: 205,
        Cigna: 180,
        UPMCHealthPlan: 100,
      },
      {
        month: "Aug",
        Aetna: 200,
        BlueCross: 220,
        Cigna: 200,
        UPMCHealthPlan: 110,
      },
      {
        month: "Sep",
        Aetna: 170,
        BlueCross: 195,
        Cigna: 175,
        UPMCHealthPlan: 95,
      },
      {
        month: "Oct",
        Aetna: 160,
        BlueCross: 185,
        Cigna: 165,
        UPMCHealthPlan: 90,
      },
      {
        month: "Nov",
        Aetna: 140,
        BlueCross: 165,
        Cigna: 145,
        UPMCHealthPlan: 80,
      },
      {
        month: "Dec",
        Aetna: 130,
        BlueCross: 155,
        Cigna: 135,
        UPMCHealthPlan: 70,
      },
    ],
    averageClaimAge: 9.8,
  },
  {
    name: "Authorization Denial - CPT Change",
    claims: 49,
    amount: 818000,
    chartData: [
      {
        month: "Jan",
        Aetna: 110,
        BlueCross: 170,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "Feb",
        Aetna: 90,
        BlueCross: 150,
        Cigna: 130,
        UPMCHealthPlan: 75,
      },
      {
        month: "Mar",
        Aetna: 160,
        BlueCross: 190,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Apr",
        Aetna: 140,
        BlueCross: 160,
        Cigna: 140,
        UPMCHealthPlan: 80,
      },
      {
        month: "May",
        Aetna: 180,
        BlueCross: 200,
        Cigna: 180,
        UPMCHealthPlan: 100,
      },
      {
        month: "Jun",
        Aetna: 150,
        BlueCross: 180,
        Cigna: 155,
        UPMCHealthPlan: 85,
      },
      {
        month: "Jul",
        Aetna: 170,
        BlueCross: 195,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "Aug",
        Aetna: 190,
        BlueCross: 210,
        Cigna: 190,
        UPMCHealthPlan: 105,
      },
      {
        month: "Sep",
        Aetna: 160,
        BlueCross: 185,
        Cigna: 165,
        UPMCHealthPlan: 90,
      },
      {
        month: "Oct",
        Aetna: 150,
        BlueCross: 175,
        Cigna: 155,
        UPMCHealthPlan: 85,
      },
      {
        month: "Nov",
        Aetna: 130,
        BlueCross: 155,
        Cigna: 135,
        UPMCHealthPlan: 75,
      },
      {
        month: "Dec",
        Aetna: 120,
        BlueCross: 145,
        Cigna: 125,
        UPMCHealthPlan: 65,
      },
    ],
    averageClaimAge: 8.7,
  },
  {
    name: "Authorization Denial - Emergent",
    claims: 44,
    amount: 756000,
    chartData: [
      {
        month: "Jan",
        Aetna: 100,
        BlueCross: 160,
        Cigna: 140,
        UPMCHealthPlan: 80,
      },
      {
        month: "Feb",
        Aetna: 80,
        BlueCross: 140,
        Cigna: 120,
        UPMCHealthPlan: 70,
      },
      {
        month: "Mar",
        Aetna: 150,
        BlueCross: 180,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "Apr",
        Aetna: 130,
        BlueCross: 150,
        Cigna: 130,
        UPMCHealthPlan: 75,
      },
      {
        month: "May",
        Aetna: 170,
        BlueCross: 190,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "Jun",
        Aetna: 140,
        BlueCross: 170,
        Cigna: 145,
        UPMCHealthPlan: 80,
      },
      {
        month: "Jul",
        Aetna: 160,
        BlueCross: 185,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Aug",
        Aetna: 180,
        BlueCross: 200,
        Cigna: 180,
        UPMCHealthPlan: 100,
      },
      {
        month: "Sep",
        Aetna: 150,
        BlueCross: 175,
        Cigna: 155,
        UPMCHealthPlan: 85,
      },
      {
        month: "Oct",
        Aetna: 140,
        BlueCross: 165,
        Cigna: 145,
        UPMCHealthPlan: 80,
      },
      {
        month: "Nov",
        Aetna: 120,
        BlueCross: 145,
        Cigna: 125,
        UPMCHealthPlan: 70,
      },
      {
        month: "Dec",
        Aetna: 110,
        BlueCross: 135,
        Cigna: 115,
        UPMCHealthPlan: 60,
      },
    ],
    averageClaimAge: 7.9,
  },
  {
    name: "Medicare Advantage - Two Midnight",
    claims: 33,
    amount: 542000,
    chartData: [
      {
        month: "Jan",
        Aetna: 90,
        BlueCross: 150,
        Cigna: 130,
        UPMCHealthPlan: 75,
      },
      {
        month: "Feb",
        Aetna: 70,
        BlueCross: 130,
        Cigna: 110,
        UPMCHealthPlan: 65,
      },
      {
        month: "Mar",
        Aetna: 140,
        BlueCross: 170,
        Cigna: 140,
        UPMCHealthPlan: 80,
      },
      {
        month: "Apr",
        Aetna: 120,
        BlueCross: 140,
        Cigna: 120,
        UPMCHealthPlan: 70,
      },
      {
        month: "May",
        Aetna: 160,
        BlueCross: 180,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Jun",
        Aetna: 130,
        BlueCross: 160,
        Cigna: 135,
        UPMCHealthPlan: 75,
      },
      {
        month: "Jul",
        Aetna: 150,
        BlueCross: 175,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "Aug",
        Aetna: 170,
        BlueCross: 190,
        Cigna: 170,
        UPMCHealthPlan: 95,
      },
      {
        month: "Sep",
        Aetna: 140,
        BlueCross: 165,
        Cigna: 145,
        UPMCHealthPlan: 80,
      },
      {
        month: "Oct",
        Aetna: 130,
        BlueCross: 155,
        Cigna: 135,
        UPMCHealthPlan: 75,
      },
      {
        month: "Nov",
        Aetna: 110,
        BlueCross: 135,
        Cigna: 115,
        UPMCHealthPlan: 65,
      },
      {
        month: "Dec",
        Aetna: 100,
        BlueCross: 125,
        Cigna: 105,
        UPMCHealthPlan: 55,
      },
    ],
    averageClaimAge: 7.2,
  },
  {
    name: "Medical Necessity Denial - Medicare",
    claims: 151,
    amount: 491000,
    chartData: [
      {
        month: "Jan",
        Aetna: 80,
        BlueCross: 140,
        Cigna: 120,
        UPMCHealthPlan: 70,
      },
      {
        month: "Feb",
        Aetna: 60,
        BlueCross: 120,
        Cigna: 100,
        UPMCHealthPlan: 60,
      },
      {
        month: "Mar",
        Aetna: 130,
        BlueCross: 160,
        Cigna: 130,
        UPMCHealthPlan: 75,
      },
      {
        month: "Apr",
        Aetna: 110,
        BlueCross: 130,
        Cigna: 110,
        UPMCHealthPlan: 65,
      },
      {
        month: "May",
        Aetna: 150,
        BlueCross: 170,
        Cigna: 150,
        UPMCHealthPlan: 85,
      },
      {
        month: "Jun",
        Aetna: 120,
        BlueCross: 150,
        Cigna: 125,
        UPMCHealthPlan: 70,
      },
      {
        month: "Jul",
        Aetna: 140,
        BlueCross: 165,
        Cigna: 140,
        UPMCHealthPlan: 80,
      },
      {
        month: "Aug",
        Aetna: 160,
        BlueCross: 180,
        Cigna: 160,
        UPMCHealthPlan: 90,
      },
      {
        month: "Sep",
        Aetna: 130,
        BlueCross: 155,
        Cigna: 135,
        UPMCHealthPlan: 75,
      },
      {
        month: "Oct",
        Aetna: 120,
        BlueCross: 145,
        Cigna: 125,
        UPMCHealthPlan: 70,
      },
      {
        month: "Nov",
        Aetna: 100,
        BlueCross: 125,
        Cigna: 105,
        UPMCHealthPlan: 60,
      },
      {
        month: "Dec",
        Aetna: 90,
        BlueCross: 115,
        Cigna: 95,
        UPMCHealthPlan: 50,
      },
    ],
    averageClaimAge: 6.5,
  },
];

export const sampleClaimDetails = [
  {
    note: "Claim denied due to lack of prior authorization - need to submit appeal with supporting documentation",
    timestamp: "Aug 15, 2024, 2:45 PM",
    claims: {
      claimId: "X7Y8Z9W0V1",
      submissionTime: "Aug 1, 2024, 10:30 AM",
      payerName: "Aetna",
      patientName: "Michael Smith",
      memberNumber: "3356789012",
      diagnosisCode: "M54.5",
      claimStatus: "Denied",
      denialReason: "No Prior Authorization",
    },

    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",

  },
  {
    note: "Claim partially paid - remaining balance due to patient responsibility",
    timestamp: "Aug 12, 2024, 11:20 AM",
    claims: {
      claimId: "B2C3D4E5F6",
      submissionTime: "Jul 25, 2024, 3:15 PM",
      payerName: "BlueCross BlueShield",
      patientName: "Sarah Johnson",
      memberNumber: "4467890123",
      diagnosisCode: "J45.909",
      claimStatus: "Partially Paid",
      amountPaid: "$750.00",
      patientResponsibility: "$250.00",
    },
    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",

  },
  {
    note: "Claim pending additional information - medical records requested",
    timestamp: "Aug 10, 2024, 9:05 AM",
    claims: {
      claimId: "G7H8I9J0K1",
      submissionTime: "Jul 30, 2024, 1:45 PM",
      payerName: "Cigna",
      patientName: "David Lee",
      memberNumber: "5578901234",
      diagnosisCode: "K21.9",
      claimStatus: "Pending",
      actionRequired: "Submit Medical Records",
    },
    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",

  },
  {
    note: "Claim approved for payment - awaiting electronic funds transfer",
    timestamp: "Aug 8, 2024, 4:30 PM",
    claims: {
      claimId: "L2M3N4O5P6",
      submissionTime: "Jul 20, 2024, 11:00 AM",
      payerName: "UnitedHealthcare",
      patientName: "Emily Davis",
      memberNumber: "6689012345",
      diagnosisCode: "E11.9",
      claimStatus: "Approved",
      approvedAmount: "$1,200.00",
      expectedPaymentDate: "Aug 15, 2024",
    },
    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",
  },
  {
    note: "Claim rejected due to incorrect coding - need to review and resubmit",
    timestamp: "Aug 5, 2024, 10:15 AM",
    claims: {
      claimId: "Q7R8S9T0U1",
      submissionTime: "Jul 28, 2024, 2:30 PM",
      payerName: "Humana",
      patientName: "Robert Wilson",
      memberNumber: "7790123456",
      diagnosisCode: "I10",
      claimStatus: "Rejected",
      rejectionReason: "Incorrect Procedure Code",
      correctCode: "99213",
    },

    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",
  },
  {
    note: "Claim under review for medical necessity - additional documentation may be required",
    timestamp: "Aug 2, 2024, 1:55 PM",
    claims: {
      claimId: "V2W3X4Y5Z6",
      submissionTime: "Jul 15, 2024, 9:45 AM",
      payerName: "Anthem",
      patientName: "Jennifer Brown",
      memberNumber: "8801234567",
      diagnosisCode: "M17.0",
      claimStatus: "Under Review",
      reviewType: "Medical Necessity",
    },

    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",

  },
  {
    note: "Claim paid in full - no further action required",
    timestamp: "Jul 30, 2024, 3:40 PM",
    claims: {
      claimId: "A7B8C9D0E1",
      submissionTime: "Jul 10, 2024, 4:20 PM",
      payerName: "Medicare",
      patientName: "William Taylor",
      memberNumber: "9912345678",
      diagnosisCode: "H40.9",
      claimStatus: "Paid",
      paidAmount: "$850.00",
      paymentDate: "Jul 28, 2024",
    },

    appeal: `Dear UPMC Health Plan Appeals Department,

    I am writing to formally appeal the denial of coverage for UPMC - Fusion of the Lumbar Spine as outlined in the Explanation of Benefits (EOB) dated 2024-07-25T09:00Z. This treatment meets medical necessity as per the policy guidelines stated by UPMC Health Plan policy MP.PA.134.
    
    The specific criteria met include the presence of other instability, as demonstrated by spondylolisthesis with progression of anterolisthesis and refractory symptoms despite a conservative management trial. Emma Johnson has a history of chronic lower back pain due to lumbar degenerative disc disease and lumbar spinal instability.
    
    Imaging studies, including MRI and X-rays, confirmed severe degenerative changes and spondylolisthesis at L4-L5. Conservative treatments, including physical therapy and medications, were ineffective, leading to the decision for surgical intervention.
    
    The clinical notes document the patient's significant functional limitations, progressive symptoms, and the failure of conservative management over a prolonged period. Specifically, the preoperative notes state: "Emma Johnson is a 58-year-old female with a longstanding history of chronic lower back pain. The patient has been experiencing progressive symptoms, including radiating pain to the legs, significant functional limitations, and worsening mobility over the past two years. Conservative treatments, including physical therapy and medications, have been ineffective." (Dr. Jane Smith, Orthopedic Surgeon)
    
    Additionally, the MRI of the lumbar spine demonstrates severe degenerative changes at L4-L5 with evidence of spinal instability, and X-rays show significant disc space narrowing and evidence of anterolisthesis at L4-L5. These findings indicate: "Emma Johnson is a 58-year-old female who presented with chronic low back pain and significant functional impairment secondary to lumbar degenerative disc disease. Conservative management including physical therapy and medication did not provide adequate relief. Following thorough discussion, surgical intervention was deemed necessary to address the spinal instability and alleviate her symptoms." (Surgical Notes)
    
    We have enclosed the necessary documentation to support this appeal, including a letter of medical necessity from the treating physician and relevant medical records. We respectfully request reconsideration of this decision and approval for the lumbar fusion procedure.
    
    Thank you for your time and attention to this matter.`,
    denialDocument:
      "https://drive.google.com/file/d/1bZIwv6fyNS6eKPu9nju1GVV868VV6koc/preview",
  },
];
